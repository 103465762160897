import { mdiPackageVariant, mdiPackageVariantClosedCheck, mdiTruckFastOutline } from "@mdi/js";
import Icon from "@mdi/react";
import { Flex, Result, Skeleton, Space } from "antd";
import clsx from "clsx";
import { NavLink, Outlet } from "react-router-dom";
import PickingPoolRequired from "../../components/app/PickingPoolRequired";
import { usePickingPool, useSelectedPickingPoolID } from "../../hooks/pickingpools";

function navLinkActive(baseCls, activeClass) {
  return ({ isActive }) =>
    clsx(baseCls, isActive && activeClass)

}

export default function Shipping() {

  const [pickingPoolId] = useSelectedPickingPoolID();

  const { pickingPool } = usePickingPool(pickingPoolId);

  if (!pickingPoolId) return <Result
    status="404"
    title="Aucun pool sélectionné"
    subTitle="Veuillez sélectionner un pool d'entrepot pour continuer."
    extra={<PickingPoolRequired />}
  />

  if (!pickingPool) return <Skeleton active style={{ padding: '1rem' }} />
  if (!pickingPool?.is_main) {
    return <Result
      status="404"
      title="Pool non principal"
      subTitle="Le pool actuel n'est pas un pool d'entrepot."
      extra={<PickingPoolRequired />}
    />
  }



  return <>
    <nav>
      <Flex>
        <ul className="nav nav-pills subnav mb-4">
          <li className="nav-item">
            <NavLink to={"list"} className={navLinkActive("nav-link", "active")}>
              <Space align="center"><Icon path={mdiPackageVariantClosedCheck} size={0.8} /> Expéditions</Space>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink to={"topick"} className={navLinkActive("nav-link", "active")}>
              <Space align="center"><Icon path={mdiPackageVariant} size={0.8} /> Préparation</Space>
            </NavLink>
          </li>

          <li className="nav-item">
            <NavLink to={"deliveries"} className={navLinkActive("nav-link", "active")}>
              <Space align="center"><Icon path={mdiTruckFastOutline} size={0.8} /> Livraisons</Space>
            </NavLink>
          </li>
        </ul>
      </Flex>
    </nav>
    <Outlet />
  </>
}
