import { Flex, Form, Input } from "antd"
import { useEffect } from "react"
import { useAuth } from "../../hooks/auth"


export const COST_BY_DAY = 945


function Prop({ label, value, children }) {
    return <Flex gap="small" >
        {label && <div>{label} : </div>}
        {children || <b>{value}</b>}
    </Flex>
}


export default function PlanningSlotCompletionForm({
    form,
    slot, task, resource,
}) {
    const { fullName, email } = useAuth()
    useEffect(() => {
        if (!slot) {
            return
        }
        form.resetFields()
        form.setFieldsValue({
            real_duration: slot.duration,
            signer: fullName + (email ? ` <${email}>` : ''),
        })
    }, [slot, form, fullName, email])

    return <Flex justify="stretch">

        <Form
            form={form}
            // layout="vertical"
            labelWrap
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 32 }}
            style={{ flex: 1 }}
        >
            <Form.Item label="Signataire" name="signer">
                <Input disabled />
            </Form.Item>
            <Form.Item label="Durée effectuée" name="real_duration" rules={[
                { required: true, message: "Veuillez spécifier une durée" },
                {
                    validator: (_, value) =>
                        value >= 0.5 ? Promise.resolve() : Promise.reject(new Error('Veuillez spécifier une durée supérieure à 0')),
                }]}>
                <Input type="number" min={1} suffix="Heures" style={{ maxWidth: 130 }} />
            </Form.Item>

            <Form.Item label="Note" name="note">
                <Input.TextArea style={{ minHeight: '7em' }} />
            </Form.Item>



        </Form>
    </Flex>
}