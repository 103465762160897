import { cache } from '../cache';
import { withApi } from './api';
import { mapToOrderModel } from './orders';

const api = withApi('chronos', '/pickingpools');
const { invalidateCache, withCache } = cache();

function mapFromPickingPoolModel(input) {
  return { ...input };;
}

function mapToPickingPoolModel(input) {

  return {
    ...input,
    orders: input.orders ? input.orders.map(o => {
      return {
        ...o,
        estimated_date_min: o.estimated_date_min ? new Date(o.estimated_date_min) : null,
        estimated_date_max: o.estimated_date_max ? new Date(o.estimated_date_max) : null,
      }
    }) : []
  }


}


export async function listPickingPools() {
  const items = await withCache(async () => {
    const { items } = await api.get();
    return items;
  }, '')
  return items ? items.map(mapToPickingPoolModel) : [];
}

export async function getPickingPool(id) {
  const item = await withCache(async () => {
    const { item } = await api.get(id);
    if (!item) {
      throw new Error('PickingPool not found');
    }
    return item;
  }, id)

  return mapToPickingPoolModel(item);;
}

export async function createPickingPool(pickingpool) {
  const { item } = await api.post('', { item: mapFromPickingPoolModel(pickingpool) });
  if (item) {
    invalidateCache(item.id)
    return mapToPickingPoolModel(item)
  }
  return undefined;
}

export async function updatePickingPool(pickingpool) {
  const res = await api.put('', { item: mapFromPickingPoolModel(pickingpool) });
  invalidateCache(pickingpool.id)
  return res ? pickingpool : undefined;
}

export async function updatePickingPoolOrders(id, orders, force = false) {
  const res = await api.put(id + "/orders", { orders, force });
  invalidateCache(id)
  return
}

export async function getPickingPoolOrders(id) {
  const items = await withCache(async () => {
    const { items } = await api.get(id + "/orders");
    return items;
  }, { id, orders: id + '/orders' })
  return items ? items.map(mapToOrderModel) : [];
}


export async function removePickingPool(id) {
  const res = await api.delete(id);
  invalidateCache(id)
  return !!res;
}


export function invalidatePickingPool(id) {
  invalidateCache(id);
}

// export async function listPickingPoolComments(resId) {
//   const { items } = await api.get(`${resId}/comments`);
//   return items;
// }

// export async function addPickingPoolComment(resId, comment) {
//   const { success } = await api.post(`${resId}/comments`, { content: comment });
//   return success;
// }