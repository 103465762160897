import { CheckCircleOutlined, CloseCircleOutlined, ExclamationCircleOutlined, MenuOutlined, ReloadOutlined } from '@ant-design/icons';
import { DndContext } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
    SortableContext,
    useSortable,
    verticalListSortingStrategy
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Badge, Button, DatePicker, Flex, Menu, Popover, Select, Space, Table, Tag } from 'antd';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { usePickingPoolOrders, useSelectedPickingPoolID } from '../../hooks/pickingpools';
import { formatDate } from '../../lib/format';
import { indexBy } from '../../lib/utils';

import { differenceInBusinessDays } from 'date-fns';
import Link from '../../components/link/Link';
import Search from '../../components/search/Search';
import SearchButton from '../../components/search/SearchButton';
import ShipmentStatus from '../../components/shipments/ShipmentStatus';
import { useAuth } from '../../hooks/auth';
import { useProjectsLoader } from '../../hooks/projects';
import { useSalesmen } from '../../hooks/resources';
import { useSearch } from '../../hooks/search';
import { useShipmentsLoader } from '../../hooks/shipments';
import { useWarehouses } from '../../hooks/warehouses';
import OrderStatus from '../orders/OrderStatus';




const DraggableRow = ({ children, ...props }) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        setActivatorNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable({
        id: props['data-row-key'],
    });
    const style = {
        ...props.style,
        transform: CSS.Transform.toString(
            transform && {
                ...transform,
                scaleY: 1,
            },
        ),
        transition,
        ...(isDragging
            ? {
                position: 'relative',
                zIndex: 9999,
            }
            : {}),
    };

    return (
        <tr {...props} ref={setNodeRef} style={style} {...attributes}>
            {React.Children.map(children, (child) => {
                if (child.key === 'sort') {
                    return React.cloneElement(child, {
                        children: (
                            <MenuOutlined
                                ref={setActivatorNodeRef}
                                style={{
                                    touchAction: 'none',
                                    cursor: 'move',
                                }}
                                {...listeners}
                            />
                        ),
                    });
                }
                return child;
            })}
        </tr>
    );
};

function OrderSelectDates({ dateMin, dateMax, onChange }) {
    const [{ date, type }, setState] = useState({ date: null, type: 'min' })

    useEffect(() => {
        if (dateMin && !new dayjs(dateMin).isSame(date, 'day')) {
            setState({ date: new dayjs(dateMin), type: 'min' })
        } else if (dateMax && !new dayjs(dateMax).isSame(date, 'day')) {
            setState({ date: new dayjs(dateMax), type: 'max' })
        }
    }, [dateMin, dateMax, date])

    const onUpdate = useCallback(({ date, type }) => {
        setState({ date, type })
        if (type === 'min') {
            onChange({ date_min: date, date_max: null })
        } else {
            onChange({ date_min: null, date_max: date })
        }
    }, [onChange])

    return <>
        <Space>
            Préparer
            <Select
                options={[
                    { label: 'à partir du', value: 'min' },
                    { label: 'pour le', value: 'max' },
                ]}
                value={type}
                onChange={(v) => {
                    onUpdate({ date, type: v })
                }}
            />
            <DatePicker value={date} format="DD/MM/YYYY" onChange={(v) => {
                onUpdate({ date: v, type })
            }} />
        </Space>
    </>
}

function OrderSelectDatesPopover({ title, dateMin, dateMax, children, onChange }) {
    const [state, setState] = useState(null)

    const onOpenChange = (open) => {
        if (!open && state) { // On close, save
            onChange({
                date_min: state.date_min,
                date_max: state.date_max
            })
        } else {
            setState(null) // Reset on open
        }
    }

    return <Popover
        title={title}
        onOpenChange={onOpenChange}
        content={<OrderSelectDates
            dateMin={state ? state.date_min : dateMin}
            dateMax={state ? state.date_max : dateMax}
            onChange={setState} />}
    // trigger="click"
    >
        {children}
    </Popover>
}


function ProjectRenderer({ project }) {
    return <Link to={"/projects/" + project.id}>{project.name}</Link>;
}


// function MenuItemWeek({ warehouse, week, count, max }) {
//     return <Flex align="center">
//         <ConfigProvider
//             theme={{
//                 components: {
//                     Progress: {
//                         circleTextFontSize: '0.8em',
//                     }
//                 }
//             }}>
//             <Progress type="circle" strokeWidth={10} size={34}
//                 strokeColor={{
//                     '0%': '#52C41A',
//                     '40%': '#52C41A',
//                     '80%': '#faad14',
//                     '100%': '#F5222D',
//                 }}
//                 percent={Math.round(100 * count / max)}
//                 status={count > max ? 'exception' : 'normal'} />
//         </ConfigProvider>
//         <Flex vertical gap={0} style={{ lineHeight: '1.2em', padding: "0.5em" }} >
//             <div><b>Semaine {week}</b></div>

//             <span style={{ fontSize: '0.7em', color: '#777', textTransform: 'uppercase' }}>{warehouse?.name}</span>
//             <div style={{ fontSize: "0.7em", color: "#888" }}><b>{count}</b> / {max}</div>
//         </Flex>
//     </Flex>
// }




function MenuItemWeek({ week }) {
    return <Flex align="center">
        <div><b>Semaine {week}</b></div>
    </Flex>
}



export default function PickingPoolOrders({
    onOrderDelete,
}) {
    const [pickingPoolId] = useSelectedPickingPoolID();
    const { can } = useAuth();
    const { pickingPool,
        orders: pickingPoolOrders,
        orderCompletions,
        reloadPickingPool,
        complete,
        removeOrder: removePickingOrder, updateOrder: updatePickingOrder, moveOrder } = usePickingPoolOrders(pickingPoolId, true);

    const [mainFilter, setMainFilter] = useState('unlocked');

    const { projects, getProject } = useProjectsLoader();
    const { getShipment } = useShipmentsLoader();

    const [warehouses] = useWarehouses();


    const [salesmen] = useSalesmen();



    const onDelete = (id) => {
        removePickingOrder(id);
        onOrderDelete && onOrderDelete(id);
        // setChanged(true);
    }

    const onUpdate = (id, { preparation_date_min, preparation_date_max }) => {

        updatePickingOrder(id, {
            preparation_date_min: preparation_date_min ? preparation_date_min.toDate() : null,
            preparation_date_max: preparation_date_max ? preparation_date_max.toDate() : null,
        })
        // setChanged(true);
    }

    const onDragEnd = ({ active, over }) => {
        moveOrder({ active, over });
        // setChanged(true);
    };


    const pickingPoolOrdersExtented = useMemo(() => {
        return pickingPoolOrders.map((order, i) => {

            const w = new dayjs(order.estimated_date_max).isoWeek()

            return {
                ...order,
                shipment: getShipment(order.shipment_id),
                estimatedDateMax: order.estimated_date_max,
                estimatedWeekMax: w || null,
                // consumedCapacities,
                position: i
            }
        })
    }, [pickingPoolOrders, getShipment]);


    const weeks = useMemo(() => {
        const weeks = pickingPoolOrdersExtented.reduce((acc, { estimatedWeekMax }) => {
            if (estimatedWeekMax) {
                acc.add(estimatedWeekMax)
            }
            return acc
        }, new Set())
        return Array.from(weeks).sort((a, b) => a - b)
    }, [pickingPoolOrdersExtented])



    const warehousesById = useMemo(() => {
        return indexBy(warehouses, 'id')
    }, [warehouses])


    const displayedPickingOrders = useMemo(() => {
        if (!mainFilter) {
            return pickingPoolOrdersExtented
        }
        if (mainFilter === 'locked') {
            return pickingPoolOrdersExtented.filter(({ locked }) => locked)
        }
        if (mainFilter === 'unlocked') {
            return pickingPoolOrdersExtented.filter(({ locked }) => !locked)
        }

        return pickingPoolOrdersExtented.filter(({ estimatedWeekMax }) => {
            return estimatedWeekMax === +mainFilter
        });
    }, [pickingPoolOrdersExtented, mainFilter])




    const { list: displayedPickingOrdersFiltered,
        filters: searchInPool, setFilters: setSearchInPool, addFilter: addPoolSearch,
        options: poolOptions } = useSearch({
            name: 'PickingPoolOrders',
            items: displayedPickingOrders,
            filterFn: useCallback((o, { thirdparties, projects, resources }, fullTextSearch) => {
                if (thirdparties && thirdparties.length && !thirdparties.includes(o.customer_code)) return false;
                if (projects && projects.length && !projects.includes(o.project_id)) return false;
                if (resources && resources.length && !resources.includes(o.salesman_code)) return false;
                return fullTextSearch(`${o.order_number} ${o.ref_internal} ${getProject(o.project_id)?.name} ${o.salesman_code}`)
            }, [getProject]),

            // thirdparties: thirdparties,
            projects: projects,
            salesmen,
            // agencies: agencies,

            enableProjectTypes: true,
            enableProjectInvoicingModes: true,
        });







    return <Flex vertical gap="middle">
        <Menu
            style={{ flex: 1 }}
            mode="horizontal"
            selectedKeys={[mainFilter]}
            onClick={({ key }) => setMainFilter(key)}
            items={
                [
                    { label: <Flex align="center" >Toutes</Flex>, key: "" },
                    { label: <Flex align="center" >À préparer</Flex>, key: "unlocked" },
                    { label: <Flex align="center" >En préparation</Flex>, key: "locked" },
                    ...weeks.map((week) => ({
                        label: <MenuItemWeek
                            // warehouse={pickingPool?.is_main ? null : warehousesById[warehouse]}
                            week={week}

                        />,
                        key: `${week}`
                    }))

                ]
            }
        />
        <div className="">

            <Flex vertical style={{ padding: 10 }} gap={5}>


                <Space.Compact gap={"small"} >
                    <Search
                        options={poolOptions}
                        values={searchInPool} onFiltersChange={setSearchInPool} />
                    <Button onClick={() => reloadPickingPool()} loading={!complete} icon={<ReloadOutlined />} />
                </Space.Compact>
            </Flex>
            <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
                <SortableContext
                    // rowKey array
                    items={pickingPoolOrders.map((i) => i.id)}
                    strategy={verticalListSortingStrategy}
                >
                    <Table
                        size='small'
                        // bordered
                        dataSource={displayedPickingOrdersFiltered}
                        rowHoverable={false}
                        pagination={
                            {
                                position: ['bottomCenter'],
                            }
                        }
                        rowKey="id"
                        components={{
                            body: {
                                row: can('pickingpool.update') && mainFilter === 'unlocked' ? DraggableRow : undefined,
                            },
                        }}

                        columns={[
                            {
                                key: 'sort',
                                width: 40,
                            },

                            {
                                title: 'Position',
                                dataIndex: 'position',
                                key: 'position',
                                width: 40,
                                render: (position) => <><strong>{position + 1}</strong> /{pickingPoolOrders.length}</>,
                                sortDirections: ['ascend'],
                                sorter: (a, b) => a.position - b.position,
                                defaultSortOrder: 'ascend',

                            },
                            {
                                title: 'Numéro',
                                dataIndex: 'order_number',
                                key: 'order_number',
                                width: 100,
                                render: (order_number, o) => <>
                                    <Link to={`/orders/${o.id}`}>{order_number}</Link>
                                    {o.from_pool_id ? '*' : ''}
                                </>
                            },

                            {
                                title: 'Statut',
                                dataIndex: 'status',
                                key: 'status',
                                width: 100,
                                render: (status) => <OrderStatus value={status} />,
                            },
                            {
                                title: 'Ref',
                                dataIndex: 'ref_internal',
                                key: 'ref_internal',
                                width: 200,
                            },
                            {
                                title: 'N° Client',
                                dataIndex: 'customer_code',
                                key: 'customer_code',
                                width: 100,
                                render: (customer_code) => <SearchButton
                                    type="thirdparties"
                                    value={customer_code}
                                    handler={addPoolSearch}>
                                    {customer_code}
                                </SearchButton>,
                            },
                            {
                                title: 'Commercial',
                                dataIndex: 'salesman_code',
                                key: 'salesman_code',
                                width: 100,
                                render: (salesman_code) => <SearchButton
                                    type="text"
                                    value={salesman_code}
                                    handler={addPoolSearch}>
                                    {salesman_code}
                                </SearchButton>
                            },
                            {
                                title: 'Affaire',
                                dataIndex: 'project_id',
                                key: 'project_id',
                                render: (project_id) => {
                                    const project = getProject(project_id);
                                    if (!project) {
                                        return null;
                                    }
                                    return <SearchButton
                                        type="projects"
                                        value={project_id}
                                        handler={addPoolSearch}>
                                        <ProjectRenderer project={project} />
                                    </SearchButton>
                                }
                            },
                            {
                                title: 'Date de livraison souhaitée',
                                dataIndex: 'shipping_date',
                                key: 'shipping_date',
                                width: 100,
                                render: (date) => formatDate(date)
                            },
                            {
                                title: 'Préparation estimée',
                                dataIndex: 'estimatedWeekMax',
                                width: 100,
                                key: 'estimatedWeekMax',
                                render: (week, { estimatedDateMax, shipping_date }) => {
                                    if (!week) {
                                        return null;
                                    }
                                    const d = differenceInBusinessDays(estimatedDateMax, shipping_date)
                                    const style = {};
                                    if (d > 0) {
                                        style.color = 'red';
                                        style.fontWeight = 600;
                                    } else if (d > -2) {
                                        style.color = '#d46b08';
                                        style.fontWeight = 500;
                                    }


                                    return <span style={style}>S{week} {d > 0 ? <Badge title="Jours ouvrés" count={'+' + d + 'jrs'} /> : null}</span>
                                }

                                // render: (date, { id, preparation_date_min, preparation_date_max, shipping_date, estimatedWeekMax, locked }) => {

                                //     // const minDate = preparation_date_min ? new dayjs(preparation_date_min) : null;
                                //     const { estimatedDateMax } = orderCompletions[id] || { estimatedDateMax: null };
                                //     if (!estimatedDateMax) {
                                //         return null;
                                //     }
                                //     const d = differenceInBusinessDays(estimatedDateMax, shipping_date)
                                //     const title = <>Date estimée de préparation: <b>{formatDate(estimatedDateMax)}</b></>
                                //     const onDateChange = ({ date_min, date_max }) => {

                                //         onUpdate(id, {
                                //             preparation_date_min: date_min,
                                //             preparation_date_max: date_max
                                //         })

                                //     }

                                //     const style = {};
                                //     if (d > 0) {
                                //         style.color = 'red';
                                //         style.fontWeight = 600;
                                //     } else if (d > -2) {
                                //         style.color = '#d46b08';
                                //         style.fontWeight = 500;
                                //     }

                                //     const content = <span style={style}>S{estimatedWeekMax}</span>


                                //     if (pickingPool.is_main && !locked && can('pickingpool.update')) {
                                //         return <OrderSelectDatesPopover title={title}
                                //             dateMin={preparation_date_min}
                                //             dateMax={preparation_date_max}
                                //             onChange={onDateChange}>
                                //             <>
                                //                 <Flex align="center" gap="small">
                                //                     {content}
                                //                     {(preparation_date_min || preparation_date_max) && <Icon path={preparation_date_min ? mdiProgressClock : mdiClockStarFourPointsOutline}
                                //                         size={0.6} color={style.color} />}
                                //                 </Flex>
                                //                 <span style={{
                                //                     marginTop: '-0.5em',
                                //                     fontWeight: '300',
                                //                     fontSize: '0.8em',
                                //                     color: '#777'
                                //                 }}>{formatDate(estimatedDateMax)}</span>
                                //             </>
                                //         </OrderSelectDatesPopover>;
                                //     } else {
                                //         return content
                                //     }
                                // },

                            },

                            {
                                title: 'Estimation dispo stock',
                                width: 100,
                                // dataIndex: 'status',
                                key: 'status',
                                render: (order) => {
                                    const { percent } = orderCompletions[order.id] || {};
                                    switch (true) {
                                        case percent === 100:
                                            return <Tag color='green' icon={<CheckCircleOutlined />}>100%</Tag>
                                        case percent < 90:
                                            return <Tag color='red' icon={<CloseCircleOutlined />}>{percent}%</Tag>
                                        default:
                                            return <Tag color='orange' icon={<ExclamationCircleOutlined />}>{percent}%</Tag>
                                    }
                                }
                            },
                            {
                                title: 'Expédition',
                                dataIndex: ['shipment', 'status'],
                                key: 'shipment_status',
                                width: 100,
                                render: (status, rec) => {
                                    // return <Popover title="Expédition" content={<div>
                                    //   <div><b>Transporteur:</b> {rec.shipment?.carrier}</div>
                                    //   <div><b>Statut:</b> <ShipmentStatus value={status} /></div>
                                    //   <div><b>Tracking:</b> {rec.shipment?.tracking}</div>
                                    // </div>}>
                                    // </Popover>
                                    return <ShipmentStatus value={status} />
                                }
                            },
                            {
                                title: 'Dépots',
                                key: 'warehouses',
                                width: 150,
                                render: (order) => {
                                    const { consumedCapacities } = orderCompletions[order.id] || { consumedCapacities: {} };
                                    return <Space>
                                        {Object.entries(consumedCapacities).map(([id, count]) => {
                                            const name = warehousesById[id]?.name || id;
                                            return <Tag key={id}>{name}: <b>{count}</b></Tag>
                                        })}
                                    </Space>
                                }
                            },

                            {
                                title: '',
                                key: 'actions',
                                width: 100,
                                render: (record) => <Space>
                                    {can('pickingpool.update') && !pickingPool.is_main && <Button type="primary" danger disabled={record.locked || record.shipment_id} onClick={() => onDelete(record.id)}>Retirer</Button>}
                                </Space>
                            }
                        ]}
                    />
                </SortableContext>
            </DndContext>


        </div>
    </Flex >
}