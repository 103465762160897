
import { Flex, Rate, Select } from "antd";
import { useMemo } from "react";
import { useProjectsLoader } from "../../hooks/projects";
import { useTasks } from "../../hooks/tasks";
import ProjectCode from "../projects/ProjectCode";
import TaskStatusLabel from "./TaskStatusLabel";



function Option({ data, showLevel, ...props }) {
  const { name, project_name, project_code, level } = data

  const descTruncated = useMemo(() => {
    if (data.description && data.description.length > 150) {
      return data.description.substr(0, 145) + '...'
    }
    return data.description
  }, [data.description])

  return (
    <Flex gap={"small"} justify="flex-start" style={{ maxWidth: '100%' }}>
      <Flex gap={0} vertical flex={1}>
        <span style={{ color: data.color, whiteSpace: 'wrap' }}>
          {name}
        </span>
        <span style={{ fontSize: '0.8em', color: '#888', whiteSpace: 'wrap' }}>
          {project_name}
        </span>
        <span style={{ fontSize: '0.8em', color: '#888', whiteSpace: 'wrap' }}>
          <ProjectCode value={project_code} />
        </span>
        <span style={{
          fontSize: '0.8em', fontStyle: 'italic', whiteSpace: 'wrap',
          paddingLeft: 5, borderLeft: '1px solid #eee',
          marginLeft: 5,
        }} title={data.description}>
          {descTruncated}
        </span>
        {showLevel &&// data.underLevel && level !== undefined &&
          <Rate value={Math.ceil(level * 3 / 5)} disabled style={{ fontSize: '0.8em' }} count={3} />
        }
      </Flex>
      <div>
        <TaskStatusLabel value={data.status} progress={data.progress} deleted={data.deleted} />
      </div>
    </Flex>
  )
}


export function TaskInput({ value, name, placeholder, style, onChange, expectedLevel, valuesFilter, tasks }) {
  const [items] = useTasks()
  const { getProject } = useProjectsLoader();
  const filteredItems = useMemo(() => {

    const list = tasks || items
    if (valuesFilter) {
      return list.filter(valuesFilter)
    }
    return list || []
  }, [items, tasks, valuesFilter])


  const changeHandler = (value) => {
    // console.log('TaskInput changeHandler', value);
    onChange({ target: { value } })
  }

  const options = useMemo(() => {

    return filteredItems.map(task => {
      const project = getProject(task.project_id)
      const data = {
        ...task,
        label: task.name,
        project_name: project?.name,
        project_code: project?.code,
      }

      return {
        ...data,
        // label: <Option data={data} />,
        // label: task.name,
      }
    })
  }, [filteredItems, getProject])

  return <Select
    showSearch
    placeholder={placeholder || 'Sélectionner une tâche'}
    optionFilterProp="children"
    filterOption={(input, option) => (`${option?.label ?? ''} ${option?.project_name ?? ''} ${option?.project_code ?? ''}`).toLowerCase().includes(input.toLowerCase())}
    filterSort={(optionA, optionB) =>
      String(optionA?.name ?? '').toLowerCase().localeCompare(String(optionB?.name ?? '').toLowerCase())
    }
    fieldNames={{
      // label: "name",
      value: "id",
    }}
    value={value}
    onChange={changeHandler}
    options={options}
    optionRender={(option) => <Option data={option.data} showLevel={expectedLevel > 0} />}
  />
}