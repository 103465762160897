import { LoadingOutlined } from "@ant-design/icons";
import { Alert, Badge, Flex, Spin, Tooltip } from "antd";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useEffect, useMemo, useState } from "react";
import { listSyncs } from "../../services/api/sync";
dayjs.extend(relativeTime);


export function SyncStatus() {
    const [syncs, setSyncs] = useState([]);
    const [now, setNow] = useState(dayjs());

    useEffect(() => {
        const interval = setInterval(() => {
            setNow(dayjs());
        }, 60000);

        return () => clearInterval(interval);
    }, []);
    useEffect(() => {
        // fetch syncs
        listSyncs().then(setSyncs);

        // update now every minute

    }, [now]);

    const last = syncs[0];

    const [status, message] = useMemo(() => {

        // return ["processing", ""];
        const isTooOld = last && now.diff(dayjs(last.created_at), 'minute') > 10;
        if (!last) {
            return ["default", "La synchronisation avec Cegid n'a jamais été effectuée"];
        }
        if (isTooOld) {
            return ["warning", "La synchronisation avec Cegid semble être interrompue"];
        }
        if (last.status === "running") {
            return ["processing", ""];
        }
        if (last.status === "failed") {
            return ["error", "La synchronisation avec Cegid a échoué"];
        }

        return ["success", ""];
    }, [last, now]);

    if (!last) {
        return <Flex>Non synchronisé</Flex>;
    }

    return (
        <Flex vertical >
            <Flex gap="small" style={{ padding: "0.5em" }}>
                <Badge dot={true} status={status} />
                <Flex gap="small">Synchro Cegid:<b>{status === 'processing' ? <Flex align="center" gap="small">
                    <Spin size="small" indicator={<LoadingOutlined spin />} /> En cours....</Flex>
                    : <Tooltip title={<Flex gap="small">
                        <Badge count={last?.num_syncs} color="green" /> élements ont été synchronisés
                    </Flex>}>{dayjs(last.created_at).fromNow()}</Tooltip>}</b></Flex>
            </Flex>
            {message && <Alert showIcon type={status} message={message} />}
        </Flex>
    );
}