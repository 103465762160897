import { CheckCircleFilled, FileDoneOutlined, ReloadOutlined } from '@ant-design/icons';
import { mdiCheckCircleOutline, mdiInvoiceCheckOutline } from '@mdi/js';
import { Badge, Button, Flex, Popconfirm, Space, Table, Typography } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Link from '../../components/link/Link';
import PrestationLabel from '../../components/prestations/PrestationLabel';
import Search from '../../components/search/Search';
import SearchButton from '../../components/search/SearchButton';
import TaskStatusLabel from '../../components/tasks/TaskStatusLabel';
import TaskTypeLabel from '../../components/tasks/TaskTypeLabel';
import { useAgencies, useSelectedAgencyID } from '../../hooks/agencies';
import { useAuth } from '../../hooks/auth';
import { usePrestations } from '../../hooks/prestations';
import { useProjectsLoader } from '../../hooks/projects';
import { useSalesmen } from '../../hooks/resources';
import { useSearch, useSearchOptionsListMemo } from '../../hooks/search';
import { taskStatuses, taskTypes, useTasks } from '../../hooks/tasks';
import { formatDate } from '../../lib/format';
import { indexBy } from '../../lib/utils';
import { removeTask } from '../../services/api/tasks';
import { loaderWrap } from '../../services/loader';


export default function TasksList() {
  const [tasks, refreshTasks, complete] = useTasks();
  const { projects, getProject } = useProjectsLoader();
  const [agencies, refreshAgencies] = useAgencies();
  const [prestations, refreshPrestations] = usePrestations();
  const [agencyID] = useSelectedAgencyID();
  const [salesmen] = useSalesmen();
  const { can } = useAuth();

  // const [filters, setFilters] = useState({});

  const [selectedTasks, setSelectedTasks] = useState([]);

  const displayedTasks = useMemo(() => {
    if (!agencies.length || !projects.length || !tasks.length) return [];
    return tasks.map(t => {
      const project = getProject(t.project_id);
      const agencyIdx = indexBy(agencies, "id")
      const agency = agencyIdx[t.agency_id];

      return {
        ...t,
        project_name: project ? project.name : 'Inconnu',
        agency_name: agency?.name || t.agency_id || ''
      }
    })
  }, [tasks, agencies, projects, getProject])

  // const filteredList = useMemo(() => {
  //   return displayedTasks.filter(task => {
  //     if (filters.tasks && filters.tasks.length && !filters.tasks.includes(task.id)) return false
  //     if (filters.projects && filters.projects.length && !filters.projects.includes(task.project_id)) return false
  //     if (filters.agencies && filters.agencies.length && !filters.agencies.includes(task.agency_id)) return false
  //     if (filters.prestations && filters.prestations.length && !filters.prestations.includes(task.prestation_id)) return false
  //     if (agencyID && task.agency_id !== agencyID) return false
  //     return true
  //   })
  // }, [displayedTasks, filters])

  const statusesOptions = useSearchOptionsListMemo({
    title: 'Status',
    name: 'statuses',
    options: taskStatuses,
    fn: useCallback((o) => ({
      value: 'status-' + o.value,
      label: o.label,
    }), []),
    optionColor: '#7cb305',
    tagColor: 'lime',
    // icon: mdiStoreOutline,
  });

  const typesOptions = useSearchOptionsListMemo({
    title: 'Type',
    name: 'types',
    options: taskTypes,
    fn: useCallback((o) => ({
      value: 'type-' + o.value,
      label: o.label
    }), []),
    optionColor: '#d48806',
    color: 'gold',
    // icon: mdiStoreOutline,
  });

  const orderValid = useSearchOptionsListMemo({
    title: 'Commande validée',
    name: 'orderValid',
    options: [
      { value: 'orderValid-true', label: 'Oui' },
      { value: 'orderValid-false', label: 'Non' },
    ],
    icon: mdiCheckCircleOutline,
    color: 'purple',
  });

  const orderInvoiced = useSearchOptionsListMemo({
    title: 'Commande facturée',
    name: 'orderInvoiced',
    options: [
      { value: 'orderInvoiced-true', label: 'Oui' },
      { value: 'orderInvoiced-false', label: 'Non' },
    ],
    icon: mdiInvoiceCheckOutline,
    color: 'purple',
  });

  const customOptions = useMemo(() => {
    return [
      ...statusesOptions,
      ...typesOptions,
      ...orderValid,
      ...orderInvoiced,
    ]
  }, [statusesOptions, typesOptions, orderValid, orderInvoiced])


  const { list: filteredList, filters, setFilters, addFilter, options, customFilters, setCustomFilters } = useSearch({
    name: 'TaskList',
    items: displayedTasks,
    filterFn: useCallback((task, { check }, fulltextSearch) => {
      if (!check('statuses', 'status-' + task.status)) return false
      if (!check('types', 'type-' + task.type)) return false
      if (!check('orderValid', task.is_order_valid ? 'orderValid-true' : 'orderValid-false')) return false
      if (!check('projects', task.project_id)) return false
      if (!check('agencies', task.agency_id)) return false
      if (!check('prestations', task.prestation_id)) return false
      if (!check('salesmen', 'salesman-' + task.salesman_code)) return false
      if (!check('orderInvoiced', task.is_invoiced ? 'orderInvoiced-true' : 'orderInvoiced-false')) return false
      // if (projects && projects.length && !projects.includes(task.project_id)) return false
      // if (agencies && agencies.length && !agencies.includes(task.agency_id)) return false
      // if (prestations && prestations.length && !prestations.includes(task.prestation_id)) return false
      // // if (salesmen && salesmen.length && salesmen.includes('salesman-' + task.salesman_code)) return false
      // if (salesmen && salesmen.length && !salesmen.includes('salesman-' + task.salesman_code)) return false
      const proj = getProject(task.project_id)
      if (!check('projectTypes', 'projectType-' + proj?.project_type)) return false
      if (!check('projectInvoicingModes', 'projectInvoicingMode-' + proj?.project_invoicing_mode)) return false
      // if (projectTypes && projectTypes.length && !projectTypes.includes('projectType-' + proj?.project_type)) return false;
      // if (projectInvoicingModes && projectInvoicingModes.length && !projectInvoicingModes.includes('projectInvoicingMode-' + proj?.project_invoicing_mode)) return false;
      if (agencyID && task.agency_id !== agencyID) return false
      return fulltextSearch(`${task.order_number} ${task.name} `)
    }, [agencyID, getProject]),
    salesmen,
    // lists
    projects: projects,
    agencies: agencies,
    prestations: prestations,
    // tasks: tasks,
    enableProjectTypes: true,
    enableProjectInvoicingModes: true,
    options: customOptions,


  });

  // console.log('search', filteredList);

  const treeList = useMemo(() => {
    if (!filteredList) return []
    const list = filteredList.map(item => ({ ...item }));
    const idx = indexBy(list, "id")

    const out = []
    list.forEach((item) => {

      if (item.parent_task_id) {
        const parent = idx[item.parent_task_id]
        if (parent) {
          if (!parent.children) {
            parent.children = []
          }
          parent.children.push(item)
          return
        }
      }
      out.push(item)
    });
    return out;
  }, [filteredList, agencies]);

  const navigate = useNavigate();
  const open = (id) => {
    navigate(`./${id}`);
  };


  const onDeleteSelected = async () => {
    for (const id of selectedTasks) {
      await loaderWrap(removeTask(id));
    }
    setSelectedTasks([]);
    refreshTasks();
  }


  return (
    <>
      <div className="box">
        <Flex gap="small" align='center' >
          {/* <Input size="large" placeholder="Rechercher une tâche..."
            prefix={<SearchOutlined />}
            onChange={(e) => setSearch(e.target.value)}
          /> */}
          <Space.Compact style={{ flex: 1 }}>
            <Search
              name='TaskList'
              options={options}
              values={filters}
              onFiltersChange={values => setFilters(values)}
              setCustomFilters={setCustomFilters}
              customFilters={customFilters}
              allowRevert
            />
            <Button onClick={() => refreshTasks()} loading={!complete} icon={<ReloadOutlined />} />
          </Space.Compact>

          {can('task.create') && <Button type="primary" onClick={() => open("*")} >Créer une tâche</Button>}
          {can('task.delete') && selectedTasks.length > 0 ?

            <Popconfirm
              title={selectedTasks.length > 1
                ? `Supprimer les ${selectedTasks.length} tâches sélectionnées ?`
                : "Supprimer la tâche sélectionnée ?"}
              description="Cette action ne peut pas être annulée."
              onConfirm={() => onDeleteSelected()}

              okText="Oui"
              cancelText="Non"
            >
              <Button type="primary" danger onClick={() => { }} >Supprimer</Button>
            </Popconfirm> : null}

        </Flex>
      </div >
      <div className="box-paddingless">

        <Table
          dataSource={treeList}
          expandable={{ defaultExpandAllRows: true }}
          rowKey={(record) => record.id}
          pagination={
            {
              position: ['bottomCenter'],
            }
          }
          rowSelection={{
            type: 'checkbox',
            getCheckboxProps: (record) => ({
              disabled: !!record.ref,
            }),
            onChange: (selectedRowKeys) => {
              setSelectedTasks(selectedRowKeys)
              // console.log(`selectedRowKeys: ${selectedRowKeys}`);
            }
          }}
          // onRow={(record, rowIndex) => {
          //   return {
          //     onClick: () => open(record.id), // click row
          //   };
          // }}
          columns={[
            {
              title: 'Nom',
              dataIndex: 'name',
              key: 'name',
              sorter: (a, b) => String(a.name).localeCompare(b.name),
              render: (text, task) => <Space direction="vertical">
                <Link to={`/tasks/${task.id}`}>{text}</Link>
                <Typography.Text type="secondary" >{task.description}</Typography.Text>
              </Space>,
            },
            {
              title: 'Prestation',
              dataIndex: 'prestation_id',
              key: 'prestation_id',
              render: (id, record) => <PrestationLabel id={id} withCode />,
            },
            {
              title: 'Affaire',
              dataIndex: 'project_name',
              key: 'project_name',
              sorter: (a, b) => String(a.project_name).localeCompare(b.project_name),
              render: (text, record) => <SearchButton
                type="projects"
                value={record.project_id}
                handler={addFilter}>
                {text}
              </SearchButton>,
            },
            {
              title: 'Commande',
              dataIndex: 'order_number',
              key: 'order_number',
              sorter: (a, b) => String(a.order_number).localeCompare(b.order_number),
              render: (text, record) => {
                let label = text;
                if (record.order_item_number) {
                  label += ` / ${record.order_item_number}`
                }
                return <SearchButton
                  type="text"
                  value={record.order_number}
                  handler={addFilter}>
                  <Space>
                    <Link to={`/orders/${record.order_id}`}>{label}</Link>
                    {record.is_order_valid && <CheckCircleFilled style={{ color: 'green' }} />}
                    {record.is_invoiced && <FileDoneOutlined style={{ color: 'purple' }} />}
                  </Space>
                </SearchButton>
              },
            },
            {
              title: 'Agence',
              dataIndex: 'agency_name',
              key: 'agency_name',
              sorter: (a, b) => String(a.agency_name).localeCompare(b.agency_name),
            },
            {
              title: 'Date de début',
              dataIndex: 'min_date',
              key: 'min_date',
              sorter: (a, b) => String(a.min_date).localeCompare(b.min_date),
              render: (text, record) => formatDate(text),
              defaultSortOrder: 'ascend',
            },
            {
              title: 'Date de fin',
              dataIndex: 'max_date',
              key: 'max_date',
              sorter: (a, b) => String(a.max_date).localeCompare(b.max_date),
              render: (text, record) => formatDate(text),
            },

            {
              title: 'Status',
              dataIndex: 'status',
              key: 'status',
              render: (text, record) => {
                let progress = 0;
                if (record.status === 'inplanning') {
                  progress = Math.round(record.planned_percent)
                } else if (record.status === 'planned') {
                  progress = Math.round(record.complete_percent)
                }
                return <Space direction='vertical'>
                  <TaskStatusLabel value={record.status} deleted={record.deleted} progress={progress != null && record.status === 'inplanning'
                    ? progress : undefined} />
                </Space>
              },
              // filters: taskStatuses.map(({ label, value }) => ({ text: label, value })),
              // defaultFilteredValue: taskStatuses.map(({ value }) => value).filter(value => value !== 'complete'),
              // onFilter: (value, record) => record.status === value,
            },
            {
              title: 'Type',
              dataIndex: 'type',
              key: 'type',
              render: (text, record) => <TaskTypeLabel value={record.type} />,
              // filters: taskTypes.map(({ label, value }) => ({ text: label, value })),
              // onFilter: (value, record) => record.type === value,
            },

            {
              title: 'Crénaux',
              dataIndex: 'metrics',
              key: 'slots',
              render: (m, task) => {
                return m?.slots_count ?
                  <Link to={`/tasks/${task.id}/planning`}><Badge count={m?.slots_count} color='blue' /></Link> : null
              },
            },
            // {
            //   title: 'Compétences',
            //   dataIndex: 'expected_level',
            //   key: 'expected_level',
            //   sorter: (a, b) => a.expected_level - b.expected_level,
            //   render: (text, record) => {
            //     return <Rate value={Math.ceil(record.expected_level * 3 / 5)} count={3} disabled />
            //   },
            // }
          ]}
        />

      </div>
      {/* <Modal title="Importer des tâches"
        centered
        open={!!uploadID}
        onOk={() => { }} onCancel={() => { }}>
        <Space direction="vertical">
          <Upload onChange={onUploadChange } action={}>
            <Button icon={<UploadOutlined />} >Importer un fichier</Button>
          </Upload>
        </Space>
      </Modal> */}
    </>
  );
}
