import { mdiPackageVariantRemove } from "@mdi/js";
import Icon from "@mdi/react";
import { Button, Flex, Popconfirm, Progress, Skeleton, Space, Table } from "antd";
import { useMemo } from "react";
import { usePickingOrders } from "../../hooks/pickingorders";
import { formatDateTime } from "../../lib/format";
import { removePickingOrder } from "../../services/api/pickingorders";
import { loaderWrap } from "../../services/loader";
import PickingProducts from "../picking/PickingProducts";
import PickingStatus from "../picking/PickingStatus";
import PickingType from "../picking/PickingType";
import ResourceLabel from "../resources/ResourceLabel";


export default function ShipmentPickingsList({ shipmentId, orderId, onChange }) {
    const [pickingorders, reload, complete] = usePickingOrders({ orderId });



    const list = useMemo(() => {
        return pickingorders.filter(picking => picking.shipment_id === shipmentId)
            .sort((a, b) => {
                if (!a.ended_at) return 1
                if (!b.ended_at) return -1
                return a.ended_at < b.ended_at ? -1 : 0
            })
    }, [pickingorders, shipmentId])



    const onDelete = async (item) => {
        await loaderWrap(removePickingOrder(item.id))
            .finally(reload)
            .finally(() => onChange && onChange())
    }

    if (!complete) return <Skeleton active />

    return <Flex vertical gap="small">


        <Table

            dataSource={list}
            rowKey="id"
            size='small'
            pagination={
                {
                    position: ['bottomCenter'],
                }
            }
            expandable={{
                expandedRowRender: (item) =>
                    <Flex className="box" vertical>
                        <PickingProducts orderId={item.order_id} items={item.items} />
                    </Flex>,
                defaultExpandedRowKeys: ['0'],
                rowExpandable: record => record.completion_rate > 0,
                // showExpandColumn: false,
                // expandRowByClick: true
            }}
            columns={[
                {
                    title: 'Type',
                    dataIndex: 'type',
                    key: 'type',
                    width: 100,
                    render: (type) => <PickingType type={type} />
                },
                {
                    title: 'Démarée le',
                    dataIndex: 'started_at',
                    key: 'started_at',
                    width: 100,
                    render: (date) => formatDateTime(date)
                },
                {
                    title: 'Terminée le',
                    dataIndex: 'ended_at',
                    key: 'ended_at',

                    width: 100,
                    render: (date) => formatDateTime(date)
                },
                {
                    title: 'Préparateur',
                    dataIndex: 'resource_id',
                    key: 'resource_id',
                    render: (id) => <ResourceLabel id={id} />
                },
                {
                    title: 'Statut',
                    dataIndex: 'status',
                    key: 'status',
                    width: 100,
                    render: (status) => <PickingStatus value={status} />
                },
                {
                    title: 'État',
                    dataIndex: 'completion_rate',
                    width: 100,
                    key: 'completion_rate',
                    render: (completion_rate, item) => {
                        if (!item.status) {
                            return <span style={{ color: 'gray' }}>—</span>
                        }
                        if (item.status === 'pending') {
                            return null
                        }
                        return <Progress
                            percent={Math.floor(100 * completion_rate)}
                            strokeColor={completion_rate < 1 ? 'orange' : null}
                        />

                    }
                },
                {
                    title: 'Note',
                    dataIndex: 'note',
                    key: 'note',
                },
                {
                    key: 'action',
                    width: 50,
                    render: (item) => <Space>
                        <Popconfirm title="Êtes-vous sûr de vouloir supprimer la préparation ?"
                            okButtonProps={{ danger: true }}
                            okText="Oui, supprimer" onConfirm={() => onDelete(item)}>
                            <Button type="primary" danger>
                                <Icon path={mdiPackageVariantRemove} size={0.8} />
                            </Button>
                        </Popconfirm>
                    </Space>
                }

            ]}
        />
    </Flex>

}