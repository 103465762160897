import { useCallback, useEffect, useState } from 'react';

import { getStocks, listItems } from '../services/api/items';
import { loaderWrap } from '../services/loader';
import { notifyError } from '../services/notification';
import { useInvalidationService } from './invalidation';

const INVALIDATION_KEY = Symbol('items');




export function useItems() {
  const [items, setItems] = useState([]);
  const [counter, invalidate] = useInvalidationService(INVALIDATION_KEY);
  const [complete, setComplete] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setComplete(false);
        // const terms = await withCache(async () => loaderWrap(listItems()));
        const terms = await loaderWrap(listItems());
        setItems(terms);
        setComplete(true);
      } catch (e) {
        notifyError(e);
      }
    })();
  }, [counter]);

  const refresh = useCallback(() => {
    invalidate();
  }, [invalidate]);

  return [items, refresh, complete];
}


export function useItemsStocks() {
  const [stocks, setStocks] = useState({});
  const [counter, invalidate] = useInvalidationService(INVALIDATION_KEY);
  const [complete, setComplete] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setComplete(false);
        // const terms = await withCache(async () => loaderWrap(listItems()));
        const stocks = await loaderWrap(getStocks());

        setStocks(stocks);
        setComplete(true);
      } catch (e) {
        notifyError(e);
      }
    })();
  }, [counter]);

  const refresh = useCallback(() => {
    invalidate();
  }, [invalidate]);



  return {
    getVirtualStock: useCallback((warehouseCode, itemCode) => {
      const whCode = warehouseCode.startsWith('wh-') ? warehouseCode.slice(3) : warehouseCode;
      return stocks[whCode]?.[itemCode]?.[1] || 0;
    }, [stocks]),
    getPhysicalStock: useCallback((warehouseCode, itemCode) => {
      const whCode = warehouseCode.startsWith('wh-') ? warehouseCode.slice(3) : warehouseCode;
      return stocks[whCode]?.[itemCode]?.[0] || 0;
    }, [stocks]),
    refresh,
    complete,
  }
}