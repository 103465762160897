
import { slotStatusesIndex } from "../../hooks/slots";

import clsx from "clsx";
import Style from "./EventItem.module.scss";



export default function ResourceSlotEventItem({
  schedulerData,
  event,
  bgColor,
  isStart,
  isEnd,
  mustAddCssClass,
  mustBeHeight,
  agendaMaxEventWidth,
  errors,
  resourcesIdx,
}) {
  const slot = resourcesIdx[event.id] || {}
  const user = resourcesIdx[slot.resource_id] || {}
  const eventTitle = schedulerData.behaviors.getEventTextFunc(schedulerData, event);
  const roundCls = isStart ? (isEnd ? 'round-all' : 'round-head') : (isEnd ? 'round-tail' : 'round-none');

  let errorType;
  for (let i = 0; i < errors?.length; i++) {
    const e = errors[i]
    if (e.level === 'error') {
      errorType = 'error'
      break
    }
    if (e.level === 'warning') {
      errorType = 'warning'
    }
  }
  if (event.slot) {
    mustAddCssClass += ` ${Style['slotStatus']} ${Style['slotStatus-' + event.slot.status]}`
  }

  bgColor = slotStatusesIndex[slot.status]?.color || bgColor

  return <div className={clsx(roundCls, mustAddCssClass, Style['slotStatus-' + event.slot?.status], {
    [Style['event-error']]: errorType === 'error',
    [Style['event-warning']]: errorType === 'warning',
    [Style['event-canceled']]: slot.status === 'canceled',
  })} key={event.id}
    style={{ height: mustBeHeight }}>
    <div className={clsx(Style["event-inner"])} style={{ lineHeight: `${mustBeHeight}px`, textDecoration: slot.deleted ? 'line-through' : 'none' }}>
      {/* <ResourceIcon type={user.type} size={0.6} /> */}
      <span style={{ textDecoration: 'none' }}>{eventTitle}</span>
    </div>
  </div>

}