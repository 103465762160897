import { Tag } from "antd";
import { pickingTypes } from "../../hooks/pickingorders";
import { indexBy } from "../../lib/utils";


const pickingTypesIdx = indexBy(pickingTypes, 'value');



export default function PickingType({ type }) {
  const { label, color } = pickingTypesIdx[type] || {
    label: type,
    color: 'gray',
  };

  return <Tag color={color}>{label}</Tag>
}