import { AlertOutlined, ArrowRightOutlined, CheckCircleOutlined, CloseCircleOutlined, ExclamationCircleOutlined, MenuOutlined, PlusCircleOutlined, ReloadOutlined } from "@ant-design/icons";
import { DndContext } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { mdiAccountPlus, mdiAnimationOutline, mdiCalendarClock, mdiCalendarPlusOutline, mdiCheckAll, mdiPackageVariantRemove } from "@mdi/js";
import Icon from "@mdi/react";
import { Badge, Button, ConfigProvider, DatePicker, Flex, Form, Menu, Modal, Popconfirm, Popover, Progress, Select, Skeleton, Space, Table, Tag, Tooltip } from "antd";
import { differenceInBusinessDays } from 'date-fns';
import dayjs from "dayjs";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import DeliveryStatus from "../../components/deliveries/DeliveryStatus";
import Link from "../../components/link/Link";
import OrderShippingModeLabel from "../../components/orders/OrderShippingModeLabel";
import OrdersTable from '../../components/orders/OrdersTable';
import Search from "../../components/search/Search";
import SearchButton from "../../components/search/SearchButton";
import ShipmentStatus from "../../components/shipments/ShipmentStatus";
import WarehouseLabel from "../../components/warehouses/WarehouseLabel";
import { useAuth } from '../../hooks/auth';
import { useDeliveries } from "../../hooks/deliveries";
import { usePickingPoolOrders, useSelectedPickingPoolID } from "../../hooks/pickingpools";
import { usePrestations } from "../../hooks/prestations";
import { useProjectsLoader } from "../../hooks/projects";
import { useResources } from "../../hooks/resources";
import { useSearch, useSearchOptionsMemo } from "../../hooks/search";
import { useShipments } from "../../hooks/shipments";
import { useWarehouses } from '../../hooks/warehouses';
import { adjustCapacity } from '../../lib/capacity';
import { formatDate, formatDateTime } from "../../lib/format";
import { indexBy } from "../../lib/utils";
import { createDelivery, updateDelivery } from "../../services/api/deliveries";
import { invalidatePickingPool } from '../../services/api/pickingpools';
import { createShipment, removeShipment, updateShipment } from "../../services/api/shipments";
import { invalidateSlotCache } from "../../services/api/slots";
import { invalidateTaskCache } from "../../services/api/tasks";
import { loaderWrap } from "../../services/loader";
import ShipmentAddPickingModal from "./ShipmentAddPickingModal";
import ShipmentDeliveryForm from "./ShipmentDeliveryForm";
import ShipmentDetails from './ShipmentDetails';

function MenuItem({ label, week, count }) {
  if (week) {
    label = `Semaine ${week}`;
  }
  return <Space style={{
    // backgroundColor: "green"
  }}>{label}
    {count ? <Badge color="blue" count={count} /> : null}</Space>
}


function MenuItemWeek({ warehouse, week, count, max }) {

  return <Flex align="center">
    <ConfigProvider
      theme={{
        components: {
          Progress: {
            circleTextFontSize: '0.8em',
          }
        }
      }}>
      <Progress type="circle" strokeWidth={10} size={34}
        strokeColor={{
          '0%': '#52C41A',
          '40%': '#52C41A',
          // '60%': '#52C41A',
          // '70%': '#A0D911',
          '80%': '#faad14',
          '100%': '#F5222D',
        }}
        percent={Math.round(100 * count / max)}
        status={count > max ? 'exception' : 'normal'} />
    </ConfigProvider>
    <Flex vertical gap={3} style={{ lineHeight: '1.2em', padding: "0.5em" }} >
      <div><b>Semaine {week}</b></div>
      <div style={{ fontSize: "0.7em", color: "#888" }}><b>{count}</b> / {max}</div>
    </Flex>
  </Flex>
}

function OrderSelectDates({ dateMin, dateMax, onChange }) {
  const [{ date, type }, setState] = useState({ date: null, type: 'min' })

  useEffect(() => {
    if (dateMin && !new dayjs(dateMin).isSame(date, 'day')) {
      setState({ date: new dayjs(dateMin), type: 'min' })
    } else if (dateMax && !new dayjs(dateMax).isSame(date, 'day')) {
      setState({ date: new dayjs(dateMax), type: 'max' })
    }
  }, [dateMin, dateMax, date])

  const onUpdate = useCallback(({ date, type }) => {
    setState({ date, type })
    if (type === 'min') {
      onChange({ date_min: date, date_max: null })
    } else {
      onChange({ date_min: null, date_max: date })
    }
  }, [onChange])

  return <>
    <Space>
      Préparer
      <Select
        options={[
          { label: 'à partir du', value: 'min' },
          { label: 'pour le', value: 'max' },
        ]}
        value={type}
        onChange={(v) => {
          onUpdate({ date, type: v })
        }}
      />
      <DatePicker value={date} format="DD/MM/YYYY" onChange={(v) => {
        onUpdate({ date: v, type })
      }} />
    </Space>
  </>
}

function OrderSelectDatesPopover({ title, dateMin, dateMax, children, onChange }) {
  const [state, setState] = useState(null)

  const onOpenChange = (open) => {
    if (!open && state) { // On close, save
      onChange({
        date_min: state.date_min,
        date_max: state.date_max
      })
    } else {
      setState(null) // Reset on open
    }
  }

  return <Popover
    title={title}
    onOpenChange={onOpenChange}
    content={<OrderSelectDates
      dateMin={state ? state.date_min : dateMin}
      dateMax={state ? state.date_max : dateMax}
      onChange={setState} />}
  // trigger="click"
  >
    {children}
  </Popover>
}



const DraggableRow = ({ children, ...props }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props['data-row-key'],
  });
  const style = {
    ...props.style,
    transform: CSS.Transform.toString(
      transform && {
        ...transform,
        scaleY: 1,
      },
    ),
    transition,
    ...(isDragging
      ? {
        position: 'relative',
        zIndex: 9999,
      }
      : {}),
  };

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if (child.key === 'sort') {
          return React.cloneElement(child, {
            children: (
              <MenuOutlined
                ref={setActivatorNodeRef}
                style={{
                  touchAction: 'none',
                  cursor: 'move',
                }}
                {...listeners}
              />
            ),
          });
        }
        return child;
      })}
    </tr>
  );
};

export default function ShipmentsList() {

  const { can } = useAuth();
  const [filterWeek, setFilterWeek] = useState('all');
  const [updating, setUpdating] = useState(false);
  // const [pickingPoolId] = useSelectedPickingPoolID();
  // const [pickingPool] = useSelectedPickingPool();
  const [pickingPoolId] = useSelectedPickingPoolID();
  const { pickingPool,
    warehouseId,
    orders: inPoolOrders,
    orderCompletions,
    addOrder: addOrderToPool,
    moveOrder,
    saveOrders,
    removeOrder: removeOrderFromPool,
    reloadPickingPool,
    saving: pickingPoolSaving,
    complete: pickingPoolComplete,

  } = usePickingPoolOrders(pickingPoolId, true);


  const [warehouses] = useWarehouses();
  const [shipments, reloadShipments, shipmentsComplete] = useShipments({ warehouseId: warehouseId, onlyActive: true });
  const [deliveries, reloadDeliveries, deliveriesComplete] = useDeliveries({ warehouseId: warehouseId, onlyActive: true });


  const { getProject } = useProjectsLoader();
  const [resources] = useResources();
  const [prestations] = usePrestations();
  const resourcesIdx = useMemo(() => indexBy(resources, 'id'), [resources]);
  const prestationsIdx = useMemo(() => indexBy(prestations, 'id'), [prestations]);

  const shipmentsIdx = useMemo(() => indexBy(shipments, 'id'), [shipments]);
  const inPoolOrdersByShipments = useMemo(() => {
    const idx = {};
    for (let i in inPoolOrders) {
      idx[inPoolOrders[i].shipment_id] = {
        ...inPoolOrders[i],
        position: +i
      }
    }
    return idx;
  }, [inPoolOrders]);


  const inPoolOrdersIdx = useMemo(() => indexBy(inPoolOrders, 'id'), [inPoolOrders]);





  const warehousesById = useMemo(() => {
    return indexBy(warehouses, 'id')
  }, [warehouses])

  const warehouseCode = useMemo(() => {
    return warehousesById[warehouseId]?.code
  }, [warehouseId, warehousesById])


  const [showShipmentDetail, setShowShipmentDetail] = useState(null);
  const [reloadOnCloseShipmentDetail, setReloadOnCloseShipmentDetail] = useState(false);


  const [showOrderPanel, setShowOrderPanel] = useState(false);

  const [addPickToOrder, setAddPickToOrder] = useState(null);

  // Form to plan a delivery
  const [formPlan] = Form.useForm();
  // const formPlanPrestationId = Form.useWatch("prestation_id", { form: formPlan })
  // const formPlanWhen = Form.useWatch("when", { form: formPlan })
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [modalFormPlanOpen, setModalFormPlanOpen] = useState(false);
  const [saving, setSaving] = useState(false);
  const openFormPlan = (shipment, delivery) => {
    setModalFormPlanOpen(true);
    setSelectedPlan({ shipment, delivery });

    const delivery_date = delivery?.delivery_date ? new dayjs(delivery.delivery_date) : new dayjs().add(1, 'day');
    // console.log(ship.delivery_date, new Date(ship.delivery_date));
    formPlan.setFieldsValue({
      when: delivery_date.hour() <= 12 ? 'morning' : 'afternoon',
      delivery_date: delivery_date,
      resource_id: delivery?.resource_id,
      prestation_id: delivery?.prestation_id,
      tracking_number: delivery?.tracking_number
    });
  }
  const onFormPlanOk = () => {
    setSaving(true);
    formPlan.validateFields()
      .then(async ({ tracking_number, resource_id, prestation_id, delivery_date, when, when_custom }) => {
        const { shipment, delivery } = selectedPlan;
        setModalFormPlanOpen(false);
        delivery_date = delivery_date.startOf('day');
        let delivery_date_end = delivery_date.clone()
        switch (when) {
          case 'morning':
            delivery_date = delivery_date.add(8, 'hours');
            delivery_date_end = delivery_date_end.add(12, 'hours');
            break;
          case 'afternoon':
            delivery_date = delivery_date.add(13, 'hours');
            delivery_date_end = delivery_date_end.add(18, 'hours');
            break;
          case 'custom':

            const [t0, t1] = when_custom;
            delivery_date = delivery_date.hour(t0.hour()).minute(t0.minute()).second(0);
            delivery_date_end = delivery_date_end.hour(t1.hour()).minute(t1.minute()).second(0);
            break;
          default:
        }
        // console.log(delivery_date, delivery_date_end);
        // return;
        if (delivery) {
          await loaderWrap(updateDelivery({
            ...delivery,
            tracking_number,
            resource_id,
            prestation_id,
            delivery_date,
            delivery_date_end,
          }));
        } else {
          await loaderWrap(createDelivery({
            tracking_number,
            order_id: shipment.order_id,
            status: 'pending',
            warehouse_id: shipment.warehouse_id,
            shipment_id: shipment.id,
            resource_id,
            prestation_id,
            delivery_date,
            delivery_date_end,

          }));
        }


        reloadShipments();
        reloadDeliveries();
        invalidateTaskCache();
        invalidateSlotCache();
      })
      .finally(() => {
        setSaving(false);
      });
  }

  // const [modalSetAuditorOpen, setModalSetAuditorOpen] = useState(false);
  // const [auditorShipment, setAuditorShipment] = useState(null);
  // const [formSetAuditor] = Form.useForm();
  // const openSetAuditor = (shipment) => {
  //   setModalSetAuditorOpen(true);
  //   setAuditorShipment(shipment);

  //   // console.log(ship.delivery_date, new Date(ship.delivery_date));
  //   formSetAuditor.setFieldsValue({

  //     auditor_id: shipment?.auditor_id,

  //   });
  // }
  // const onFormSetAuditorOk = () => {
  //   formSetAuditor.validateFields()
  //     .then(async ({ auditor_id }) => {
  //       const shipment = auditorShipment;
  //       setModalSetAuditorOpen(false);


  //       await loaderWrap(updateShipment({
  //         ...shipment,
  //         auditor_id
  //       }));

  //       reloadShipments();
  //     })
  // }

  // const onUpdateShipmentDates = async (id, { date_min, date_max }) => {
  //   await loaderWrap(updateShipment({
  //     ...shipmentsIdx[id],
  //     date_min,
  //     date_max
  //   }));
  //   reloadShipments();
  // }

  const onDragEnd = ({ active, over }) => {
    moveOrder({
      active: {
        id: shipmentsIdx[active.id]?.order_id
      },
      over: over ? {
        id: shipmentsIdx[over.id]?.order_id
      } : null
    });
    // saveOrders(true);
    // setChanged(true);
  };

  const onDelete = async (id) => {
    await loaderWrap(removeShipment(id));
    invalidateTaskCache();
    invalidateSlotCache();
    invalidatePickingPool();
    reloadShipments();
    reloadDeliveries();
    reloadPickingPool();
  }

  const onVerifyOKShipment = async (shipment) => {
    await loaderWrap(updateShipment({
      ...shipment,
      status: 'ready'
    }));
    reloadShipments();
    invalidateTaskCache();
  }

  const onCloseShipment = async (shipment) => {
    await loaderWrap(updateShipment({
      ...shipment,
      status: 'delivered'
    }));
    reloadShipments();
    reloadDeliveries();
    invalidateTaskCache();
    invalidateSlotCache();
  }

  const onCreateShipment = async (order) => {
    const ship = await loaderWrap(createShipment({
      order_id: order.id,
      status: 'pending',
      warehouse_id: warehouseId,
    }))
    invalidateTaskCache();
    await reloadShipments(true);
    reloadPickingPool();
  }

  const onDeleteOrderFromPool = async (orderId) => {
    removeOrderFromPool(orderId, true)
  }




  const shipmentsWithUnpickedOrders = useMemo(() => {
    const out = [...shipments]
    const orderWithShipments = indexBy(out, "order_id")


    inPoolOrders.forEach(o => {
      if (!orderWithShipments[o.id]) {
        // Cretate an empty shipment
        out.push({ id: 'noship-' + o.id, order_id: o.id, order: o });
      }
    })

    return out;

  }, [shipments, inPoolOrders]);



  const orders = useSearchOptionsMemo(shipmentsWithUnpickedOrders, (s) => {
    return { id: s.order_id, name: s.order?.ref_internal || `Commande n°${s.order?.order_number}` }
  });

  const thirdparties = useSearchOptionsMemo(shipmentsWithUnpickedOrders, (s) => {
    const id = s.order?.third_party_code || s.order?.customer_code
    return {
      id, name: s.order?.third_party_name || s.order?.customer_name || 'Tiers n°' + id
    }
  });

  const usedProjects = useSearchOptionsMemo(shipmentsWithUnpickedOrders, (s) => {
    const id = s.order?.project_id;
    return {
      id, name: getProject(id)?.name || 'Affaire n°' + id
    }
  }, [getProject]);


  const { list, filters, setFilters, addFilter, options,
    customFilters, setCustomFilters
  } = useSearch({
    name: "shipments",
    items: useMemo(() => {
      const items = shipmentsWithUnpickedOrders.map(s => {
        const po = inPoolOrdersByShipments[s.id] || {};
        return {
          ...s,
          third_party_code: s.order?.third_party_code || s.order?.customer_code,
          third_party_name: s.order?.third_party_name || s.order?.customer_name,
          deliveries: deliveries.filter(d => d.shipment_id === s.id),
          inPicking: s.picking_orders?.filter(po => po.status !== 'finished').length > 0,
          position: po.position,
          estimated_date_min: po.estimated_date_min,
          estimated_date_max: po.estimated_date_max,
          estimated_week: po.estimated_date_max ? new dayjs(po.estimated_date_max).isoWeek() : null,
          // estimatedDateMax,
          // estimatedWeekMax,
        }
      })
      items.sort((a, b) => a.position - b.position);
      return items;
    }, [shipmentsWithUnpickedOrders, deliveries, inPoolOrdersByShipments, orderCompletions]),
    filterFn: useCallback(({ order }, { orders, thirdparties, projects }, fulltextSearch) => {
      if (orders && orders.length && !orders.includes(order.id)) return false;
      if (thirdparties && thirdparties.length && !thirdparties.includes(order.third_party_code || order.customer_code)) return false;
      if (projects && projects.length && !projects.includes(order.project_id)) return false;
      return fulltextSearch(`${order.order_number} ${getProject(order.project_id)?.name} ${order.salesman_code}`)
    }, [getProject]),

    projects: usedProjects,
    thirdparties,
    orders,
  });

  const pendingList = useMemo(() => list.filter(s => !s.pending_pickings_ids?.length && !s.complete_pickings_ids), [list]);
  const unplannedList = useMemo(() => list.filter(s => s.deliveries.length === 0), [list]);
  const verifyingList = useMemo(() => list.filter(s => s.status === 'verifying'), [list]);
  const shippedList = useMemo(() => list.filter(s => s.status === 'shipped'), [list]);
  // const byWeekList = useMemo(() => {
  //   return Object.entries(weeks)
  //     .map(([week, count]) => ({
  //       week,
  //       count,
  //       list: list.filter(s => s.deliveries.find(d => dayjs(d.delivery_date).isoWeek() === week))
  //     }))
  //     .reduce((acc, { week, list }) => {
  //       acc[week] = list;
  //       return acc;
  //     }, {});
  // }, [list, weeks]);


  const inPoolOrdersExtended = useMemo(() => {
    return inPoolOrders.map((order) => {
      const { estimatedDateMax, consumedCapacities } = orderCompletions[order.id] || { estimatedDateMax: null, consumedCapacities: 0 };
      const w = new dayjs(order.estimated_date_max).isoWeek()

      return {
        ...order,
        shipment: shipmentsIdx[order.shipment_id],
        estimatedDateMax: order.estimated_date_max,
        estimatedWeekMax: order.estimated_date_max ? w : null,
        consumedCapacities,
      }
    })
  }, [inPoolOrders, shipmentsIdx, orderCompletions]);


  const statsByWeek = useMemo(() => {
    return Object.values(inPoolOrdersExtended.reduce((acc, { estimatedWeekMax, consumedCapacities }) => {
      if (!estimatedWeekMax) {
        return acc;
      }
      Object.entries(consumedCapacities).forEach(([wid, count]) => {
        const key = `${estimatedWeekMax}-${wid}`
        const isCurrentWeek = estimatedWeekMax === new dayjs().isoWeek();
        if (wid !== warehouseId) {
          return; // Skip other warehouses
        }
        if (!acc[key]) {
          acc[key] = {
            key, count: 0,
            max: isCurrentWeek ? adjustCapacity((pickingPool.capacities[wid] || 0), 'week') : (pickingPool.capacities[wid] || 0) * 5,
            week: estimatedWeekMax,
            warehouse: wid
          }
        }
        acc[key].count += count
      })
      return acc
    }, {}))
  }, [inPoolOrdersExtended, pickingPool, warehouseId])


  const finalList = useMemo(() => {
    if (filterWeek === 'all') {
      return list;
    }
    if (filterWeek === 'pending') {
      return pendingList
    }
    if (filterWeek === 'unplanned') {
      return unplannedList
    }
    if (filterWeek === 'verifying') {
      return verifyingList
    }
    if (filterWeek === 'shipped') {
      return shippedList
    }
    const week = +filterWeek;

    return list.filter(s => s.estimated_week === week);
  }, [list, pendingList, unplannedList, verifyingList, shippedList, filterWeek]);


  const reload = useCallback(() => {
    reloadShipments();
    reloadDeliveries();
    reloadPickingPool();
  }, [reloadShipments, reloadDeliveries, reloadPickingPool]);


  const onCloseOrdersModal = useCallback(async () => {
    setShowOrderPanel(false);
    setUpdating(true);

    await reloadShipments(true);
    reloadPickingPool();
    setUpdating(false);
  }, [reloadShipments, reloadPickingPool, saveOrders, setShowOrderPanel]);



  const showSortHandle = filterWeek === 'all';

  const allComplete = pickingPoolComplete && shipmentsComplete && deliveriesComplete && !saving && !updating;

  if (!allComplete) {
    return <Skeleton style={{ padding: '1rem' }} active />
  }

  return (
    <>

      <div className="box-paddingless">
        <Flex style={{ padding: 10 }} gap="small" justify="space-between" align="center">

          <Space.Compact style={{ flex: 1 }}>
            <Search
              name="ShipmentsList"
              options={options}
              setCustomFilters={setCustomFilters}
              customFilters={customFilters}
              values={filters} onFiltersChange={setFilters} />

            <Button title="Rafraichir" onClick={reload} shape="rounded" icon={<ReloadOutlined />} />

          </Space.Compact>
          <Button type="primary" onClick={() => setShowOrderPanel(true)} icon={<PlusCircleOutlined />} >Ajouter des commandes</Button>
        </Flex>

        <Menu
          style={{ flex: 1 }}
          mode="horizontal"
          selectedKeys={[filterWeek]}
          onClick={({ key }) => setFilterWeek(key)}
          items={
            [
              { label: <MenuItem label="Toutes" />, key: 'all' },
              { label: <MenuItem label="Non affectées" count={pendingList?.length} />, key: 'pending' },
              { label: <MenuItem label="Non plannifiées" count={unplannedList?.length} />, key: 'unplanned' },
              { label: <MenuItem label="À vérifier" count={verifyingList?.length} />, key: 'verifying' },
              { label: <MenuItem label="Expédiés" count={shippedList?.length} />, key: 'shipped' },

              { type: 'divider' },

              ...statsByWeek.map(({ key, count, max, week, warehouse }) => ({
                label: <MenuItemWeek
                  warehouse={pickingPool?.is_main ? null : warehousesById[warehouse]}
                  week={week}
                  count={count}
                  max={max} />,
                key: week
              }))
            ]
          }
        />
        <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
          <SortableContext
            // rowKey array
            items={finalList.map((i) => i.id)}
            strategy={verticalListSortingStrategy}
          >
            <Table
              key={"table:" + filterWeek}
              // bordered
              dataSource={finalList}
              // rowHoverable={false}
              rowKey="id"
              size='middle'
              // locale={{
              //   emptyText: "Il n'y a plus d'expéditions à afficher"
              // }}
              pagination={
                {
                  showSizeChanger: true,
                  position: ['bottomCenter'],
                }
              }
              components={{
                body: {
                  row: showSortHandle ? DraggableRow : undefined,
                },
              }}
              // expandable={{
              //   expandedRowRender: (item) =>
              //     <Flex className="box" vertical>
              //       <ShipmentDetails id={item.id} />
              //     </Flex>,
              //   defaultExpandedRowKeys: ['0'],
              //   rowExpandable: record => record.completion_rate < 1 && record.status !== 'pending',
              //   // showExpandColumn: false,
              //   // expandRowByClick: true
              // }}
              // onRow={(record, rowIndex) => {
              //   return {
              //     onClick: () => openSetResource(record.id), // click row
              //   };
              // }}
              columns={[

                {
                  key: 'sort',
                  width: 40,
                },
                // {
                //   title: 'ID',
                //   dataIndex: 'id',
                //   key: 'id',
                //   width: 100,
                //   render: (id, { status }) => status && <ID value={id} /> // <Link to={`/picking/${id}`}>{id.replace(/^pickingorder-.{4}/, '')}</Link>
                //   // sorter: (a, b) => String(a.code).localeCompare(b.code),
                // },
                {
                  title: 'Priorité',
                  dataIndex: 'position',
                  key: 'position',
                  defaultSortOrder: "ascend",
                  render: (position) => (position + 1 || '-') + '/' + list.length,
                  sorter: showSortHandle ? null : (a, b) => a.position - b.position,
                },
                {
                  title: 'Commande',
                  dataIndex: ['order', 'order_number'],
                  key: 'order_number',
                  width: 80,
                  render: (order_number, o) => <SearchButton
                    type="orders" value={o.order.id} handler={addFilter}>
                    <Space>
                      <Link to={`/orders/${o.order_id}`}>{order_number}</Link>
                    </Space>
                  </SearchButton>
                },
                {
                  title: 'Affaire / Client',
                  dataIndex: ['order', 'project_id'],
                  key: 'project_id',
                  render: (project_id, s) => {
                    const project = getProject(project_id);
                    return <Flex vertical>
                      <SearchButton type="projects" value={project_id} handler={addFilter}>
                        <strong>{project && project.name}</strong>
                      </SearchButton>
                      <SearchButton type="thirdparties" value={s.third_party_code} handler={addFilter} >
                        {s.third_party_name}
                      </SearchButton>
                      {s.order?.nature === 'CTT' && <Space>
                        <Tag color="gold">Transfert</Tag>
                        <ArrowRightOutlined />
                        {s.order.warehouse_dest_id && <WarehouseLabel id={s.order.warehouse_dest_id} />}
                      </Space>
                      }
                    </Flex>
                  }
                },
                // {
                //   title: 'Tiers',
                //   dataIndex: 'third_party_code',
                //   key: 'third_party_code',
                //   render: (third_party_code, s) => {
                //     return <SearchButton type="thirdparties" value={third_party_code} handler={addFilter} >
                //       {third_party_code}
                //     </SearchButton>
                //   }
                // },
                {
                  title: 'Date de livraison souhaitée',
                  dataIndex: ['order', 'shipping_date'],
                  key: 'shipping_date',
                  width: 120,
                  render: (date) => formatDate(date),

                  sorter: showSortHandle ? null : (a, b) => new Date(a.order?.shipping_date) - new Date(b.order?.shipping_date),
                },
                {
                  title: 'Préparation estimée',
                  dataIndex: 'estimated_week',
                  width: 100,
                  key: 'estimated_week',

                  render: (week, { estimated_date_max, shipping_date }) => {
                    if (!estimated_date_max) {
                      return null;
                    }
                    const d = differenceInBusinessDays(estimated_date_max, shipping_date)
                    const style = {};
                    if (d > 0) {
                      style.color = 'red';
                      style.fontWeight = 600;
                    } else if (d > -2) {
                      style.color = '#d46b08';
                      style.fontWeight = 500;
                    }

                    return <Flex vertical>
                      <div style={style}>S{week} {d > 0 ? <Badge title="Jours ouvrés" count={'+' + d + 'jrs'} /> : null}</div>
                      <span style={{
                        marginTop: '-0.5em',
                        fontWeight: '300',
                        fontSize: '0.8em',
                        color: '#777'
                      }}>{formatDate(estimated_date_max)}</span>
                    </Flex>
                  },
                  // render: (date, { id, order_id, date_min, date_max, estimatedDateMax, estimatedWeekMax, order }) => {

                  //   // const minDate = preparation_date_min ? new dayjs(preparation_date_min) : null;

                  //   if (!estimatedDateMax) {
                  //     return null;
                  //   }
                  //   const d = differenceInBusinessDays(estimatedDateMax, order?.shipping_date)
                  //   const title = <>Date estimée de préparation: <b>{formatDate(estimatedDateMax)}</b></>
                  //   const onDateChange = ({ date_min, date_max }) => {

                  //     onUpdateShipmentDates(id, {
                  //       date_min,
                  //       date_max
                  //     })

                  //   }

                  //   const style = {};
                  //   if (d > 0) {
                  //     style.color = 'red';
                  //     style.fontWeight = 600;
                  //   } else if (d > -2) {
                  //     style.color = '#d46b08';
                  //     style.fontWeight = 500;
                  //   }

                  //   const content = <span style={style}>S{estimatedWeekMax}</span>


                  //   if (pickingPool.is_main && can('pickingpool.update')) {
                  //     return <OrderSelectDatesPopover title={title}
                  //       dateMin={date_min}
                  //       dateMax={date_max}
                  //       onChange={onDateChange}>
                  //       <>
                  //         <Flex align="center" gap="small">
                  //           {content}
                  //           {(date_min || date_max) && <Icon path={date_max ? mdiProgressClock : mdiClockStarFourPointsOutline}
                  //             size={0.6} color={style.color} />}
                  //         </Flex>
                  //         <span style={{
                  //           marginTop: '-0.5em',
                  //           fontWeight: '300',
                  //           fontSize: '0.8em',
                  //           color: '#777'
                  //         }}>{formatDate(estimatedDateMax)}</span>
                  //       </>
                  //     </OrderSelectDatesPopover>;
                  //   } else {
                  //     return content
                  //   }
                  // },

                },

                {
                  title: 'Stock',

                  width: 100,
                  // dataIndex: 'status',
                  key: 'status',
                  render: ({ order_id }, item) => {
                    const { percent } = orderCompletions[order_id] || {};
                    switch (true) {
                      case percent === 100:
                        return <Tag color='green' icon={<CheckCircleOutlined />} >100%</Tag>
                      case percent < 90:
                        return <Tag color='red' icon={<CloseCircleOutlined />} >{percent}%</Tag>
                      default:
                        return <Tag color='orange' icon={<ExclamationCircleOutlined />} >{percent}%</Tag>
                    }
                  }
                },
                {
                  title: 'État',
                  dataIndex: 'completion_rate',
                  width: 100,
                  key: 'completion_rate',
                  render: (completion_rate, item) => {

                    if (false && completion_rate >= 1) {
                      return <Tag color="green" onClick={() => setShowShipmentDetail({ shipment: item, tab: "pickings" })}>Complète</Tag>
                    } else if (!item.status) {
                      return <span style={{ color: 'gray' }}>—</span>
                    }
                    if (item.status === 'pending') {
                      return null
                    }
                    return <Progress onClick={() => setShowShipmentDetail({ shipment: item, tab: "missing" })}
                      percent={Math.floor(100 * completion_rate)}
                      strokeColor={completion_rate < 1 ? 'orange' : null}
                    />
                  }
                },
                {
                  title: 'Mode d\'expédition',
                  dataIndex: ['order', 'shipping_mode'],
                  key: 'shipping_mode',
                  width: 120,
                  render: (mode) => <OrderShippingModeLabel value={mode} />
                },
                {
                  title: 'Date de livraison',
                  dataIndex: 'deliveries',
                  key: 'deliveries',
                  width: 120,
                  render: (deliveries, ship) => {

                    return <Space>
                      <Space direction="vertical">
                        {deliveries?.map(d => <Tooltip
                          title={<div>
                            <div>Livraison prévue le <b>{formatDate(d.delivery_date)}</b></div>
                            <div><b>{prestationsIdx[d.prestation_id]?.name}</b> par <b>{resourcesIdx[d.resource_id]?.name}</b></div>

                            <Flex justify="space-between">
                              <DeliveryStatus value={d.status} />
                              <Button type="primary" size="small" onClick={() => openFormPlan(ship, d)}>Modifier</Button>
                            </Flex>

                          </div>}

                          key={d.id}
                        >
                          <div style={{ whiteSpace: 'nowrap', cursor: "pointer" }} onClick={() => openFormPlan(ship, d)}>
                            {formatDateTime(d.delivery_date)}
                          </div></Tooltip>)}
                      </Space>
                      <Space.Compact>
                        <Tooltip title={!deliveries.length ? "Planifier la livraison" : "Ajouter une livraison"}>
                          <Button type={!deliveries.length ? "primary" : "dashed"} onClick={() => openFormPlan(ship, null)}
                            shape={!deliveries.length ? "round" : "circle"} >
                            <Icon path={!deliveries.length ? mdiCalendarClock : mdiCalendarPlusOutline} size={0.8} />
                          </Button>
                        </Tooltip>
                        {!deliveries.length && ship.status !== 'verifying' && <Tooltip title="Marquer comme terminé">
                          <Button shape="round" variant="dashed" color="green" onClick={() => onCloseShipment(ship)}>
                            <Icon path={mdiCheckAll} size={0.8} />
                          </Button>
                        </Tooltip>}
                      </Space.Compact>
                    </Space>


                  }
                },

                {
                  title: 'Status',
                  dataIndex: 'status',
                  key: 'status',
                  render: (status) => <ShipmentStatus value={status} />
                },
                {
                  title: 'Refs restantes',
                  key: 'items_count',
                  dataIndex: ['order', 'remaining_items_count'],
                },
                {
                  title: 'Préparations',
                  key: 'picking_ids',
                  render: (shipment) => {
                    const { pending_pickings_ids, complete_pickings_ids, order, id } = shipment;
                    const total = (pending_pickings_ids?.length || 0) + (complete_pickings_ids?.length || 0)
                    const color = complete_pickings_ids?.length > 0 && !pending_pickings_ids?.length ? 'green' : 'orange'

                    return <Space.Compact>
                      {total > 0 && <Button count={total} shape="round" color={color} variant="solid" onClick={() => setShowShipmentDetail({ shipment, tab: "pickings" })}>{total}</Button>}

                      <Tooltip title="Ajouter un préparateur">
                        <Button shape="round" variant={total > 0 ? "dashed" : "solid"} color={total ? "default" : "gold"}
                          onClick={() => setAddPickToOrder({ ...order, shipment_id: id, type: 'picking' })} >
                          <Icon path={mdiAccountPlus} size={0.8} />
                        </Button>
                      </Tooltip>

                    </Space.Compact>

                    // return <Space>
                    //   {ship.need_to_be_verified && <AlertOutlined style={{ color: 'red' }} />}
                    //   {auditor_id
                    //     ? <div onClick={() => openSetAuditor(ship)} style={{ cursor: 'pointer', textDecoration: 'underline' }}>
                    //       <ResourceLabel id={auditor_id} clickable={false} />
                    //     </div>
                    //     : <Button type={ship.need_to_be_verified ? "default" : "dashed"} size="small" onClick={() => openSetAuditor(ship)}>Affecter</Button>}
                    // </Space>
                  }
                },
                {
                  title: 'Vérificateurs',
                  key: 'auditor',

                  render: (shipment) => {
                    const { pending_verifying_ids, complete_verifying_ids, need_to_be_verified, order, id } = shipment;
                    const total = (pending_verifying_ids?.length || 0) + (complete_verifying_ids?.length || 0)
                    const color = complete_verifying_ids?.length > 0 && !pending_verifying_ids?.length ? 'green' : 'orange'
                    // need_to_be_verified = true
                    return <Space>
                      {need_to_be_verified && <AlertOutlined style={{ color: 'red' }} />}

                      <Tooltip title="Ajouter un vérificateur">
                        <Space.Compact>
                          {total > 0 && <Button count={total} shape="round" color={color} variant="solid" onClick={() => setShowShipmentDetail({ shipment, tab: "pickings" })}>{total}</Button>}
                          <Button shape="round" variant={need_to_be_verified && !total ? "solid" : "dashed"} color={need_to_be_verified && !total ? 'orange' : 'default'}
                            onClick={() => setAddPickToOrder({ ...order, shipment_id: id, type: "verifying" })}>
                            <Icon path={mdiAccountPlus} size={0.8} />
                          </Button>
                        </Space.Compact>
                      </Tooltip>
                      {/* {auditor_id
                        ? <div onClick={() => openSetAuditor(ship)} style={{ cursor: 'pointer', textDecoration: 'underline' }}>
                          <ResourceLabel id={auditor_id} clickable={false} />
                        </div>
                        : <Button type={ship.need_to_be_verified ? "default" : "dashed"} size="small" onClick={() => openSetAuditor(ship)}>Affecter</Button>} */}
                    </Space>
                  }
                },

                {
                  key: 'actions',
                  align: "right",
                  width: 100,
                  render: (record) => {
                    if (!record.status) {
                      return <Space.Compact>
                        <Button type="primary" onClick={() => onCreateShipment(record.order)}>Créer l'expédition</Button>
                        <Popconfirm title="Vous êtes sur le point de retirer cette commande du pool de préparation. Continuer ?"
                          okButtonProps={{ danger: true }}
                          okText="Oui, supprimer" onConfirm={() => onDeleteOrderFromPool(record.order.id)}>
                          <Button danger>
                            <Icon path={mdiPackageVariantRemove} size={0.8} />
                          </Button>
                        </Popconfirm>
                      </Space.Compact>
                    }

                    return <Space.Compact>

                      {/* {record.deliveries?.length
                        ? null
                        :
                        <>

                          {!record.inPicking && record.picking_orders?.length > 0 &&
                            <ConfigProvider
                              theme={{
                                components: {
                                  Button: {
                                    defaultBg: green.primary, // green
                                    defaultColor: 'white',
                                    defaultBorderColor: green.primary,
                                    defaultActiveBg: green.primary,
                                    defaultActiveColor: 'white',
                                    defaultHoverBg: green[4],
                                    defaultHoverColor: 'white',
                                    defaultHoverBorderColor: green[5],
                                  },
                                }
                              }}
                            >
                              <Tooltip title="Marquer comme terminé">
                                <Button onClick={() => onCloseShipment(record)}>
                                  <Icon path={mdiCheckAll} size={0.8} />
                                </Button>
                              </Tooltip>
                            </ConfigProvider>
                          }
                        </>} */}
                      {/* <Tooltip title="Ajouter un préparateur">
                        <Button><Icon path={mdiAccountPlus} size={0.8} onClick={() => setAddPickToOrder({ ...record.order, shipment_id: record.id })} /></Button>
                      </Tooltip> */}
                      <Popconfirm title="Êtes-vous sûr de vouloir annuler cette expédition ?"
                        description={<div>
                          Cette action: <ul>
                            <li>annulera la planification de la livraison </li>
                            <li>supprimera les préparations</li>
                            <li>marquera la commande comme non en attente de préparation</li>
                          </ul>
                        </div>}
                        okButtonProps={{ danger: true }}
                        okText="Oui, supprimer" onConfirm={() => onDelete(record.id)}>
                        <Button type="primary" danger>
                          <Icon path={mdiPackageVariantRemove} size={0.8} />
                        </Button>
                      </Popconfirm>
                    </Space.Compact>
                  }
                }

              ]}
            />
          </SortableContext>
        </DndContext>





      </div >

      <ShipmentAddPickingModal
        warehouseId={warehouseId}
        picking={addPickToOrder}
        onFinished={() => {
          setAddPickToOrder(null);
          reloadShipments();
        }} />

      <Modal title="Planification de la livraison"
        open={modalFormPlanOpen}
        maskClosable={false}
        onOk={onFormPlanOk} onCancel={() => setModalFormPlanOpen(false)}>
        <p>
          Planification de la livraison de la commande <b>{selectedPlan?.shipment?.order?.order_number}</b>
        </p>
        <ShipmentDeliveryForm form={formPlan} delivery={selectedPlan?.delivery} />
      </Modal>

      {/* <Modal title="Affecter un vérificateur"
        open={modalSetAuditorOpen}
        maskClosable={false}
        destroyOnClose
        onOk={onFormSetAuditorOk} onCancel={() => setModalSetAuditorOpen(false)}>
        <ShipmentSetAuditorForm form={formSetAuditor} warehouseId={warehouseId} required={auditorShipment?.need_to_be_verified} />
      </Modal> */}

      <Modal placement='bottom'
        width="90%"
        style={{ height: '90vh' }}
        title={<Flex align='center' gap="small">
          <Icon path={mdiAnimationOutline} size={1} />
          <span>Ajouter des commandes</span>
        </Flex>}
        open={showOrderPanel}
        closable
        maskClosable
        onCancel={() => onCloseOrdersModal()}
        footer={null}
      >
        <OrdersTable
          onAddToPickingPool={addOrderToPool}
          inPoolOrderIds={inPoolOrders.map(o => o.id)}
          warehouseId={warehouseId}
          showPools
          filterFn={(o) => {
            if (o.remaining_items_count_by_warehouse[warehouseCode] === 0) {
              return false; // Skip orders with no items in this warehouse
            }
            if (inPoolOrdersIdx[o.id]) {
              return false; // Skip orders already in the pool
            }
            return true;
            // return !inPoolOrders.find(po => po.id === o.id
          }}
        />
      </Modal>

      <Modal title="Détail des préparations"
        width={"80%"}
        maskClosable
        onCancel={() => {
          setShowShipmentDetail(null)
          reloadOnCloseShipmentDetail && reload();
          setReloadOnCloseShipmentDetail(false);
        }}
        open={showShipmentDetail}
        destroyOnClose
        footer={null}
      >
        {showShipmentDetail &&
          <ShipmentDetails id={showShipmentDetail.shipment.id} tab={showShipmentDetail.tab} onChange={() => setReloadOnCloseShipmentDetail(true)} />}

      </Modal>

    </>);
}