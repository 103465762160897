import { green, orange, red, yellow } from '@ant-design/colors';
import { AlertOutlined, ArrowRightOutlined, CheckCircleOutlined, Loading3QuartersOutlined, ReloadOutlined, StopOutlined } from "@ant-design/icons";
import { mdiPackageVariantClosedCheck, mdiPackageVariantRemove, mdiPlayBoxOutline } from "@mdi/js";
import Icon from "@mdi/react";
import { Button, Checkbox, ConfigProvider, Flex, Form, Input, InputNumber, Menu, Modal, Popconfirm, Progress, Space, Table, Tag, Tooltip } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import Avatar from "../../components/avatar/Avatar";
import Link from "../../components/link/Link";
import OrderShippingModeLabel from "../../components/orders/OrderShippingModeLabel";
import PickingStatus from "../../components/picking/PickingStatus";
import PickingType from '../../components/picking/PickingType';
import Search from "../../components/search/Search";
import TableEditableCell from "../../components/table/TableEditableCell";
import TableEditableRow from "../../components/table/TableEditableRow";
import WarehouseLabel from '../../components/warehouses/WarehouseLabel';
import { usePickingPoolOrders, useSelectedPickingPoolID } from "../../hooks/pickingpools";
import { usePrestations } from "../../hooks/prestations";
import { useProjectsLoader } from "../../hooks/projects";
import { useResources } from "../../hooks/resources";
import { useSearch, useSearcher, useSearchOptionsMemo } from "../../hooks/search";
import { useShipments } from "../../hooks/shipments";
import { adjustCapacity } from "../../lib/capacity";
import { formatDate, formatDateTime } from "../../lib/format";
import { indexBy } from "../../lib/utils";
import { removePickingOrder, updatePickingOrder } from "../../services/api/pickingorders";
import { invalidatePickingPool } from "../../services/api/pickingpools";
import { loaderWrap } from "../../services/loader";


function SegmentResource({ label, resource, capacity, maxCapacity, selected }) {
  if (!resource) {
    return <Flex align="center" style={{ height: 60 }}>
      <div>{label}</div>
    </Flex>
  }
  return <Flex vertical gap={0} style={{ padding: 10 }}>
    <Space align="center" >
      <div >
        <Avatar username={resource.name} size={24} />
      </div>
      <div style={{ fontWeight: 500 }} >{resource.name}</div>
    </Space>
    <div style={{ fontSize: '0.7em', lineHeight: '0.8em', opacity: 0.8 }}>{capacity}{maxCapacity > 0 && <> / {Math.ceil(maxCapacity)}</>}</div>
    {maxCapacity > 0 && <Progress percent={Math.round(100 * capacity / maxCapacity)}
      strokeColor={{
        '0%': green.primary,
        '70%': green.primary,
        '85%': yellow.primary,
        '90%': orange.primary,
        '100%': red.primary
      }}
      status="active"
      showInfo={false}
    // percentPosition={{
    //   align: 'end',
    //   type: 'inner',
    // }}
    // size={[100, 10]}
    // format={(v) => <span style={{
    //   fontSize: '0.5em',
    //   paddingRight: 5,
    // }}>{v}%</span>}
    // status={capacity > maxCapacity ? 'exception' : 'normal'}
    // status="normal"
    />}
  </Flex>
}


export default function ShippingPicking() {
  const [pickingPoolId] = useSelectedPickingPoolID();
  const { pickingPool,
    orders: incomingOrders,
    warehouseId,
    // removeOrder: removeOrderFromPool,
    reloadPickingPool
  } = usePickingPoolOrders(pickingPoolId);
  // const [pickingOrders, reloadPickingOrders, pickingOrderComplete] = usePickingOrders();
  const [displayMode, setDisplayMode] = useState('');

  const [shipments, reloadShipments, shipmentComplete] = useShipments({ warehouseId, onlyActive: true });


  const [prestations] = usePrestations();

  const pickingOrders = useMemo(() => {
    const out = [];
    shipments.forEach(s => {
      if (!s.picking_orders) return;
      out.push(...s.picking_orders

        .map(p => (
          {
            ...p,
            shipment: s,
            order: s.order,
          }
        )));
    });
    return out;
  }, [shipments]);



  const warehouseCode = warehouseId?.split('-')[1]
  const warehouseCapacity = pickingPool?.capacities?.[warehouseId] || 0;
  const prestationId = prestations.find(p => p.code === 'CMDPREP' + warehouseCode)?.id;
  const [resources, , resourcesComplete] = useResources({ prestationId });
  const { getProject } = useProjectsLoader();

  const [selectedPickingOrder, setSelectedPickingOrder] = useState(null);

  const isComplete = prestationId && resourcesComplete;

  const reload = () => {
    reloadShipments();
    invalidatePickingPool(pickingPoolId);
    reloadPickingPool();
  }

  const loading = !isComplete || !shipmentComplete;


  const shipmentsOrdersIndex = useMemo(() => indexBy(shipments, "order_id"), [shipments])




  // Start
  const onOrderPickingStart = async (item) => {
    await updatePickingOrder({
      ...item,
      status: 'started',
    })
      .then(reload);
  }
  // Finish
  const [formFinish] = Form.useForm();
  const [finishLoading, setFinishLoading] = useState(false);
  const [modalFinishOpen, setModalFinishOpen] = useState(false);
  const [selectedOrderLines, setSelectedOrderLines] = useState([]);
  const { list: filteredSelectedOrderLines, search: orderLinesSearch, setSearch: setSearchOrderLines } = useSearcher(selectedOrderLines,
    (item, search, san) => san(`${item.line_number} ${item.item_code} ${item.label}`).includes(search))
  const onOrderPickingFinish = (item) => {
    setModalFinishOpen(true);
    setSelectedPickingOrder(item);

    const existings = pickingOrders
      .filter(p => p.order_id === item.order_id && item.id !== p.id && (!p.type || p.type === 'picking'))
      .reduce((acc, p) => {
        p.items?.forEach((i) => {
          const existing = acc.find(e => e.line_number === i.line_number);
          if (existing) {
            existing.picked_quantity += i.picked_quantity;
          } else {
            acc.push({ ...i });
          }
        });
        return acc;
      }, []);

    const existingIdx = indexBy(existings, 'line_number');


    let lines = item.order?.items
      .filter(({ item_type }) => item_type === 'MAR' || item_type === 'NOM')


    switch (item.type) {
      case 'verifying':
        lines = lines.filter(({ line_number }) => existingIdx[line_number]?.expected_quantity)
          .map((item) => ({
            ...item,
            picked_quantity: (existingIdx[item.line_number]?.picked_quantity || 0),
          }))
        break;
      case 'fixing':
        const toFixLines = indexBy(item.items, 'line_number');
        lines = lines.filter(({ line_number }) => toFixLines[line_number])
          .map((item) => {
            return {
              ...item,
              remain_quantity: toFixLines[item.line_number].expected_quantity,
              picked_quantity: toFixLines[item.line_number].expected_quantity,
              // ...toFixLines[item.line_number]
            }
          })
        break;
      default:
        lines = lines.filter(({ line_number }) => !existingIdx[line_number] || existingIdx[line_number].picked_quantity < existingIdx[line_number].expected_quantity)
          .filter(({ remain_quantity }) => remain_quantity > 0)
          .map((item) => ({
            ...item,
            to_prepare_quantity: item.remain_quantity - (existingIdx[item.line_number]?.picked_quantity || 0),
            picked_quantity: item.remain_quantity - (existingIdx[item.line_number]?.picked_quantity || 0)
          }))
        break;
    }


    setSelectedOrderLines(lines);
  }
  const onSetPickedQty = (row, value) => {
    setSelectedOrderLines(selectedOrderLines
      .map((r) => r.line_number === row.line_number
        ? { ...row, picked_quantity: value }
        : r));
  };

  const onModalFinishOk = () => {
    setFinishLoading(true);
    formFinish.validateFields()
      .then(async ({ resource_id, note, non_conform_ok }) => {
        await loaderWrap(updatePickingOrder({
          ...selectedPickingOrder,
          status: 'finished',
          note: note,
          non_conform_ok,
          items: selectedOrderLines.map((l) => {
            return {
              line_id: l.id,
              line_number: l.line_number,
              picked_quantity: l.picked_quantity,
              expected_quantity: l.remain_quantity
            }
          })
        }))
        reload();
        setModalFinishOpen(false);
      })
      .finally(() => {
        setFinishLoading(false);
      });
  }

  const onDelete = async (item) => {
    await loaderWrap(removePickingOrder(item.id))
      .finally(reload);

  }

  const onCancel = async (item) => {
    await loaderWrap(updatePickingOrder({
      ...item,
      status: 'pending',
    }))
      .finally(reload);

  }


  // const onDeleteOrderFromPool = async (orderId) => {
  //   removeOrderFromPool(orderId, true)
  // }

  const capacitiesByResource = useMemo(() => {
    const capacities = {};
    // const incomingOrdersIdx = indexBy(incomingOrders, 'id');
    pickingOrders.forEach(({ status, resource_id, order_id, order }) => {
      if (status === 'finished') return;

      const { remaining_items_count } = order || { remaining_items_count: 1 };
      if (!remaining_items_count) return
      capacities[resource_id] = (capacities[resource_id] || 0) + remaining_items_count;
    });
    return capacities;
  }, [pickingOrders]);


  const resourceId = displayMode.startsWith('res:') ? displayMode.replace('res:', '') : null;

  const orders = useMemo(() => {
    if (!resourceId) {
      const pickedOrdersIndex = indexBy(pickingOrders, 'order_id');
      const list = incomingOrders
        .filter(o => !pickedOrdersIndex[o.id])
        .map((o, i) => ({
          ...o,
          shipment: shipmentsOrdersIndex[o.id],
        }));
      return list;
    }
  }, [incomingOrders, pickingOrders, resourceId, shipmentsOrdersIndex]);



  const orderPositions = useMemo(() => {
    if (!pickingPool) return {};
    const idx = {}
    for (let i in pickingPool.orders) {
      idx[pickingPool.orders[i].order_id] = +i;
    }
    return idx;
  }, [pickingPool]);



  const activeResourceMap = useMemo(() => indexBy(pickingOrders.filter(p => p.status !== 'finished'), 'resource_id'), [pickingOrders]);
  const activeResources = useMemo(() => resources.filter(r => activeResourceMap[r.id]), [resources, activeResourceMap]);

  const allPickings = useMemo(() => {
    return pickingOrders
      .filter(p => (!resourceId || p.resource_id === resourceId) && p.status !== 'finished')
      .map(p => ({
        ...p.order,
        ...p,
        position: orderPositions[p.order_id],
      }))
      .sort((a, b) => a.position - b.position);
  }, [pickingOrders, resourceId, orderPositions]);



  const projectsFilter = useSearchOptionsMemo(allPickings, (s) => {
    const id = s.order?.project_id;
    return {
      id, name: getProject(id)?.name || 'Affaire n°' + id
    }
  }, [getProject]);

  const ordersFilters = useSearchOptionsMemo(allPickings, (o) => {
    return {
      id: o.order_id,
      name: o.order_number || 'Commande n°' + o.id
    }
  }, [orders]);

  const { list: pickings, filters, setFilters, addFilter, options, customFilters, setCustomFilters } = useSearch({
    name: "ShipmentsPickings",
    items: allPickings,
    filterFn: useCallback(({ order }, { orders, projects }, fulltextSearch) => {
      if (orders && orders.length && !orders.includes(order.id)) return false;
      // if (thirdparties && thirdparties.length && !thirdparties.includes(order.third_party_code || order.customer_code)) return false;
      if (projects && projects.length && !projects.includes(order.project_id)) return false;
      return fulltextSearch(`${order.order_number} ${getProject(order.project_id)?.name} ${order.salesman_code}`)
    }, [getProject]),

    projects: projectsFilter,
    orders: ordersFilters,

  });



  useEffect(() => {
    if (displayMode) return;
    if (!activeResources?.length) return;

    setDisplayMode('res:' + activeResources[0].id);
  }, [activeResources, displayMode]);


  const maxCapacity = adjustCapacity(warehouseCapacity / activeResources.length, 'day');

  const isConform = useMemo(() => {
    if (selectedPickingOrder?.type === 'verifying' || selectedPickingOrder?.type === 'fixing') {
      return selectedOrderLines?.filter(l => l.picked_quantity !== l.remain_quantity).length === 0;
    }
    return selectedOrderLines?.filter(l => l.picked_quantity !== l.to_prepare_quantity).length === 0;

  }, [selectedPickingOrder, selectedOrderLines]);

  if (!isComplete) return null


  return (
    <Flex vertical gap="large">

      <div className="box-paddingless">
        <Space.Compact style={{ width: '100%', padding: 10 }}>
          <Search
            name="PickingList"
            options={options}
            setCustomFilters={setCustomFilters}
            customFilters={customFilters}
            values={filters} onFiltersChange={setFilters}
          />

          <Button title="Rafraichir" onClick={reload} shape="rounded" loading={loading} icon={<ReloadOutlined />} />

        </Space.Compact>
        <Menu
          style={{ flex: 1 }}
          mode="horizontal"
          selectedKeys={[displayMode]}
          onClick={({ key }) => setDisplayMode(key)}
          items={
            [
              ...activeResources
                .map(r => ({
                  label: <SegmentResource
                    resource={r}
                    selected={displayMode === 'res:' + r.id}
                    capacity={capacitiesByResource[r.id]}
                    maxCapacity={maxCapacity} />,
                  key: 'res:' + r.id
                }))
            ]
          }
        />
        <Table
          key="table-pickings"
          // bordered
          dataSource={pickings}
          rowKey="id"
          size='middle'
          pagination={
            {
              position: ['bottomCenter'],
            }
          }
          // onRow={(record, rowIndex) => {
          //   return {
          //     onClick: () => openSetResource(record.id), // click row
          //   };
          // }}
          columns={[
            // {
            //   title: 'Priorité',
            //   dataIndex: 'position',
            //   key: 'position',
            //   defaultSortOrder: "ascend",
            //   render: (position) => (position + 1 || '-') + '/' + pickings.length,
            //   sorter: (a, b) => a.position - b.position,
            // },
            {
              title: 'Numéro',
              dataIndex: 'order_number',
              key: 'order_number',
              render: (order_number, o) =>
                <Space>
                  <Link to={`/orders/${o.order_id}`}>{order_number}</Link>
                  {(o.shipment?.need_to_be_verified || o.shipment?.auditor_id) && <Tooltip title="Cette préparation devra être vérifiée"><Tag color="#f50"><AlertOutlined style={{ color: 'white' }} /></Tag></Tooltip>}
                </Space>
            },
            {
              title: 'Affaire',
              dataIndex: 'project_id',
              key: 'project_id',
              render: (project_id, pick) => {
                const project = getProject(project_id);
                return <div>
                  {project && <div>{project.name}</div>}
                  {pick.order?.nature === 'CTT' && <Space>
                    <Tag color="gold">Transfert</Tag>
                    <ArrowRightOutlined />
                    {pick.order.warehouse_dest_id && <WarehouseLabel id={pick.order.warehouse_dest_id} />}
                  </Space>
                  }
                </div>
              }
            },
            {
              title: 'Date de livraison',
              dataIndex: 'shipping_date',
              key: 'shipping_date',
              render: (date) => formatDate(date),
              // defaultSortOrder: "ascend",
              sorter: (a, b) => new Date(a.shipping_date) - new Date(b.shipping_date),
            },
            {
              title: 'Mode d\'expédition',
              dataIndex: 'shipping_mode',
              key: 'shipping_mode',
              render: (mode) => <OrderShippingModeLabel value={mode} />
            },


            {
              title: 'Début de préparation',
              dataIndex: 'started_at',
              key: 'started_at',
              render: (date) => formatDateTime(date)
            },
            {
              title: 'Status',
              dataIndex: 'status',
              key: 'status',
              render: (status) => <PickingStatus value={status} />
            },

            {
              title: 'Nb réfs',
              key: 'items_count',
              dataIndex: 'remaining_items_count',
              // render: (record) => <a href={`/orders/${record.id}`}>Voir</a>
            },
            {
              title: 'Type',
              key: 'type',
              dataIndex: 'type',
              render: (type) => <PickingType type={type} />
            },
            {
              title: '',
              key: 'actions',
              align: "right",
              render: (record) => {
                return <Space>
                  {record.status === 'pending' && <Space>
                    <Tooltip title="Commencer">
                      <Button type="primary" onClick={() => onOrderPickingStart(record)}><Icon path={mdiPlayBoxOutline} size={0.8} /> Commencer</Button>
                    </Tooltip>
                    <Popconfirm title="Êtes-vous sûr de vouloir supprimer la préparation ?"
                      okButtonProps={{ danger: true }}
                      okText="Oui, supprimer" onConfirm={() => onDelete(record)}>
                      <Button type="primary" danger>
                        <Icon path={mdiPackageVariantRemove} size={0.8} />
                      </Button>
                    </Popconfirm>
                  </Space>
                  }
                  {record.status === 'started' && <>

                    <Tooltip title="Valider la préparation">
                      <Button type="primary" variant="solid" color="green" onClick={() => onOrderPickingFinish(record)}>
                        <Icon path={mdiPackageVariantClosedCheck} size={0.8} /> Terminer
                      </Button>
                    </Tooltip>
                    <Popconfirm title="Êtes-vous sûr de vouloir annuler cette préparation ?"
                      okButtonProps={{ danger: true }}
                      okText="Oui, annuler" onConfirm={() => onCancel(record)}>
                      <Button type="primary" variant="filled" color="red" >
                        <Icon path={mdiPackageVariantRemove} size={0.8} />
                      </Button>
                    </Popconfirm>
                  </>

                  }


                </Space>
              }
            }
          ]}
        />
      </div>



      <Modal
        title={selectedPickingOrder?.type === 'verifying' ? 'Validation de la préparation' : 'Fin de préparation'}
        destroyOnClose={true}
        maskClosable={false}
        centered
        open={modalFinishOpen}
        onOk={onModalFinishOk}
        confirmLoading={finishLoading}
        cancelText="Annuler"
        width={"80%"}
        onCancel={() => {
          setModalFinishOpen(null)
          formFinish.resetFields();
        }}
        footer={

          [
            <Button key="back" onClick={() => setModalFinishOpen(false)}>
              Annuler
            </Button>,

            selectedPickingOrder?.type === 'verifying' ?
              (isConform
                ? <Button key="submit" variant="solid" color="green" loading={finishLoading} onClick={onModalFinishOk}>Valider</Button>
                : <Button key="submit" type="primary" danger onClick={onModalFinishOk}>Non conforme</Button>)
              :
              (isConform
                ? <Button key="submit" variant="solid" color="green" loading={finishLoading} onClick={onModalFinishOk}>Complète</Button>
                : <Button key="submit" variant="solid" color="orange" onClick={onModalFinishOk}>Valider la préparation incomplètte</Button>
              )
          ]}
      >
        <Flex vertical gap={"small"}>
          <Input.Search placeholder="Rechercher..." value={orderLinesSearch}
            onChange={e => setSearchOrderLines(e.target.value)} />
          <Table
            dataSource={filteredSelectedOrderLines}
            rowKey="line_number"
            size='small'
            pagination={
              {
                position: ['bottomCenter'],
              }
            }
            components={{
              body: {
                // row: (...props) => <TableEditableRow {...props} editableContext={LinesEditableContext} />,
                // cell: (...props) => <TableEditableCell {...props} editableContext={LinesEditableContext} />,
                row: TableEditableRow,
                cell: TableEditableCell,
              },
            }}
            columns={[
              {
                title: 'Ligne',
                dataIndex: 'line_number',
                key: 'line_number',
                filterSearch: true,
                onFilter: (value, record) => record.line_number.includes(value),
              },
              {
                title: 'Référence',
                dataIndex: 'item_code',
                key: 'item_code',
              },
              {
                title: 'Nom',
                dataIndex: 'label',
                key: 'label',
              },
              {
                title: 'Quantité commandée',
                dataIndex: 'quantity',
                key: 'quantity',
                width: 100,
              },
              {
                title: 'Quantité à préparer',
                dataIndex: 'remain_quantity',
                key: 'remain_quantity',
                width: 100,
              },
              {
                title: 'Préparé',
                dataIndex: 'picked_quantity',
                key: 'picked_quantity',
                render: (picked_quantity, record) => {
                  let color = 'green'
                  let icon = <CheckCircleOutlined style={{ color }} />
                  let changed = false
                  if (picked_quantity < record.remain_quantity) {
                    color = 'orange'
                    icon = <Loading3QuartersOutlined style={{ color }} />
                    changed = true
                  }
                  if (+picked_quantity === 0) {
                    color = 'red'
                    icon = <StopOutlined style={{ color }} />
                  }
                  return <ConfigProvider
                    theme={{
                      components: {
                        InputNumber: {
                          colorText: color,
                        }
                      }
                    }}
                  ><InputNumber
                      suffix={icon}
                      style={{ color, fontWeight: changed ? 'bold' : 'normal' }}
                      value={picked_quantity}
                      max={record.remain_quantity}
                      min={0}
                      step={1}
                      onChange={value => onSetPickedQty(record, value)}
                    /></ConfigProvider>
                }
              }
            ]} />
        </Flex>
        <Form form={formFinish} layout="vertical">
          <Form.Item label="Commentaire" name="note">
            <Input.TextArea />
          </Form.Item>
          {!isConform && selectedPickingOrder?.type === 'verifying' &&
            <Form.Item label="Ne pas générer de rectification" name="non_conform_ok" valuePropName="checked" >
              <Checkbox>En cochant cette case j'accepte la non-conformité de la préparation et en assume la responsabilité</Checkbox>
            </Form.Item>}
        </Form>

      </Modal>

    </Flex >
  );
}