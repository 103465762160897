import { Affix, Button, Flex, Popover, Typography } from "antd";
import { useMemo, useState } from "react";
import { routes } from "../../routes";
import { Breadcrumbs } from "../breadcrumb/Breadcrumbs";

import { mdiStoreOutline, mdiTrayFull } from "@mdi/js";
import Icon from "@mdi/react";
import { useSelectedAgency } from "../../hooks/agencies";
import { useAuth } from "../../hooks/auth";
import { resourceRoles } from "../../hooks/resources";
import { SyncStatus } from "../sync/SyncStatus";
import AgencySwitcher from "./AgencySwitcher";
import Style from "./AppTop.module.scss";
import PickingPoolSwitcher from "./PickingPoolSwitcher";

export default function AppTop() {
  const [sticked, setSticked] = useState(false);
  // const [userName, setUserName] = useState("");
  // const { user, signOut } = useAuthenticator((context) => [context.user]);
  const { user, signOut, fullName, defaultAgencyId, defaultPoolId, role } = useAuth()
  const [agency] = useSelectedAgency()

  const roleLabel = useMemo(() => {
    return resourceRoles.find((r) => r.value === role)?.label
  }, [role])

  // useEffect(() => {
  //   (async () => {
  //     const { idToken } = (await fetchAuthSession()).tokens ?? {};
  //     const claims = idToken?.payload;

  //     if (claims) {
  //       setUserName([claims.given_name, claims.family_name].join(" "));
  //     }
  //   })()
  //   // console.log(await getCurrentUser())
  // }, [user.userId]);


  return (
    <Affix
      // offsetTop={sticked ? 0 : 0} 
      onChange={(affixed) => setSticked(affixed)}
      target={() => document.getElementById("app-main")}>
      <div className={Style.top + (sticked ? " " + Style.sticked : "")}>
        <Flex align="center" justify="space-between" >

          <Breadcrumbs routes={routes} />

          <Popover content={
            <Flex vertical gap="middle">
              <Typography.Text style={{ textAlign: "right" }} type="secondary">&mdash; {roleLabel}</Typography.Text>
              {!defaultAgencyId ?
                <Flex align="center" gap="small">
                  <Icon path={mdiStoreOutline} size={1} />
                  <AgencySwitcher />
                </Flex>
                : null}
              {!defaultPoolId ?
                <Flex align="center" gap="small">
                  <Icon path={mdiTrayFull} size={1} />
                  <PickingPoolSwitcher />
                </Flex>
                : null}
              <SyncStatus />
              <Button danger href="/" onClick={(e) => {
                e.preventDefault();
                signOut();
              }}>Déconnexion</Button>
            </Flex>
          }>
            <Flex align="flex-end" justify="center" vertical gap={0}>
              <div className={Style.username}>{fullName}</div>
              {agency ?
                <div className={Style.agency}>{agency?.name}</div>
                : null}
            </Flex>
          </Popover>
        </Flex>
      </div>
    </Affix >
  );
}