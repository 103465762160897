import { Badge, Button, Flex, Modal } from 'antd';
import React, { useState } from 'react';
import { usePickingPoolOrders, useSelectedPickingPoolID } from '../../hooks/pickingpools';

import { mdiPackageVariantClosed } from '@mdi/js';
import Icon from '@mdi/react';
import OrdersTable from '../../components/orders/OrdersTable';
import PickingPoolOrders from '../../components/pickingpools/PickingPoolOrders';
import { useAuth } from '../../hooks/auth';





export default function OrdersList() {
  const [pickingPoolId] = useSelectedPickingPoolID();
  const { can } = useAuth();
  const { pickingPool,
    orders: pickingPoolOrders,
    reloadPickingPool,
    addOrder } = usePickingPoolOrders(pickingPoolId, true);


  const [selectedOrders, setSelectedOrders] = useState([]);

  const [showPickingPool, setShowPickingPool] = useState(false);



  const onAdd = (id) => {
    addOrder(id);
    // setChanged(true);
  }



  return <Flex vertical gap="middle">
    <OrdersTable
      onAddToPickingPool={pickingPool?.is_main ? null : onAdd}
      inPoolOrderIds={pickingPoolOrders.map(o => o.id)}

      showPools={false}

      extraActionsButtons={<Flex gap="small">
        {pickingPoolId && !pickingPool?.is_main &&
          <Button type="primary"
            onClick={() => setShowPickingPool(true)}>En préparation <Badge count={pickingPoolOrders?.length} /></Button>}
      </Flex>}

      onSelectionChange={setSelectedOrders}
      selectedOrdersIds={selectedOrders}
    />

    {can('pickingpool.view') && <>
      <Modal
        width="95%"
        destroyOnClose
        title={<Flex align='center' gap="small">
          <Icon path={mdiPackageVariantClosed} size={1} />
          <span>Commandes en préparation pour <b>{pickingPool?.name}</b></span>
        </Flex>}
        open={showPickingPool}
        footer={null}
        onCancel={() => { setShowPickingPool(false); reloadPickingPool() }}>

        <PickingPoolOrders />
      </Modal>
    </>
    }

  </Flex >
}