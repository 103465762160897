import { Flex, Table } from "antd";
import { useMemo } from "react";
import { useItemsStocks } from "../../hooks/items";
import { useShipment } from "../../hooks/shipments";
import { indexBy } from "../../lib/utils";



export default function ShipmentProducts({ shipmentId, missingOnly = false }) {

  const { shipment } = useShipment(shipmentId);

  const { getVirtualStock } = useItemsStocks();




  const missingItems = useMemo(() => {
    if (!shipment) return [];
    const lines = indexBy(shipment.order.items, 'line_number');

    const missingItems = shipment.items.filter(i => !missingOnly || (missingOnly && i.expected_quantity > i.picked_quantity));
    return missingItems?.map((item, i) => {
      const orderLine = lines[item.line_number] || {};

      return {
        ...orderLine,
        ...item,
        id: i,
        stock: getVirtualStock(shipment.warehouse_id, orderLine.item_code),
      }
    });
  }, [shipment, missingOnly, getVirtualStock])
  return <Flex vertical gap="small">

    <Table
      dataSource={missingItems}
      rowKey="id"
      size="small"

      columns={[
        {
          title: 'Ligne',
          dataIndex: 'line_number',
          key: 'line_number',
        },
        {
          title: 'Réf',
          dataIndex: 'item_code',
          key: 'item_code',
        },
        {
          title: 'Nom',
          dataIndex: 'label',
          key: 'label',
        },
        {
          title: 'Stock virtuel',
          dataIndex: 'stock',
          key: 'stock',
          render: (stock, { expected_quantity, picked_quantity }) => {
            if (stock < (expected_quantity - picked_quantity)) {
              return <b style={{ color: 'red' }}>{stock}</b>
            }
            return <b>{stock}</b>
          }
        },
        {
          title: 'Commandé',
          dataIndex: 'quantity',
          key: 'quantity',
          width: 100,
        },
        {
          title: 'A préparer',
          dataIndex: 'expected_quantity',
          key: 'expected_quantity',
          width: 100,
        },
        {
          title: 'Préparé',
          dataIndex: 'picked_quantity',
          key: 'picked_quantity',
          width: 100,
        },
        {
          title: 'Manquant',
          key: 'missing',
          width: 100,
          render: (item) => <b>{item.expected_quantity - item.picked_quantity}</b>
        }
      ]}
    />

  </Flex>
}