import { Tag } from "antd";


export default function PickingStatus({ value }) {
  const status = {
    'pending': ['En attente', 'default'],
    'started': ['En cours', 'blue'],
    'finished': ['Terminé', 'green'],
  };
  const stat = status[value] || [];
  const label = stat[0] || 'Non affecté';
  const color = stat[1] || 'red';
  return <Tag color={color}>{label}</Tag>
}