import { Skeleton, Tabs } from "antd";
import { useMemo } from "react";
import ShipmentPickingsList from "../../components/shipments/ShipmentPickingsList";
import ShipmentProducts from "../../components/shipments/ShipmentProducts";
import { useShipment } from "../../hooks/shipments";






export default function ShipmentDetails({ id, tab, onChange }) {
    const { shipment } = useShipment(id);

    const items = useMemo(() => shipment ? [
        // {
        //     key: "stocks",
        //     label: "Stocks",
        //     children: <div>Stocks</div>
        // },
        {
            key: "products",
            label: "Produits",
            children: <ShipmentProducts shipmentId={shipment.id} />
        },
        {
            key: "missing",
            label: "Produits manquants",
            children: <ShipmentProducts shipmentId={shipment.id} missingOnly />
        },
        {
            key: "pickings",
            label: "Préparations",
            children: <ShipmentPickingsList shipmentId={shipment.id} orderId={shipment.order_id} onChange={onChange} />
        }

    ] : [], [shipment, onChange])


    if (!shipment) return <Skeleton active />

    return <Tabs defaultActiveKey={tab || items[0].key} tabPosition="top"
        items={items} />


}