import { withApi } from './api';

const api = withApi('chronos', '/sync');

function mapFromSyncModel(input) {
  return input;
}

function mapToSyncModel(input) {
  return input;
}

export async function listSyncs() {
  const { items } = await api.get();
  return items ? items.map(mapToSyncModel) : [];
}

export async function getSync(id) {
  const { item } = await api.get(id);
  const sync = mapToSyncModel(item);
  if (!sync) {
    throw new Error('Sync not found');
  }
  return sync;
}

export async function setSync(sync) {
  const res = await api.put('', { item: mapFromSyncModel(sync) });
  return res ? sync : undefined;
}

export async function removeSync(id) {
  const res = await api.delete(id);
  return !!res;
}
