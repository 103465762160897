/* eslint-disable import/prefer-default-export */


export function cache(enabled = true) {
  const cache = new Map();
  const keyTags = new Map();

  const pendings = new Map();

  const addPending = (k, updater) => {
    return new Promise((resolve) => {
      const p = pendings.get(k);
      if (p) {
        p.push(resolve);
      } else {
        pendings.set(k, [resolve]);
        updater(() => {
          // Flush
          const p = pendings.get(k);
          pendings.delete(k);
          for (const r of p) {
            r();
          }
        });
      }
    });
  };



  return {
    invalidateCache: (id) => {
      if (id === undefined) {
        cache.clear();
        keyTags.clear();
        return;
      }
      if (typeof id === 'object') {
        // Invalidate by tags
        for (const [k, t] of keyTags.entries()) {
          for (const [tag, value] of Object.entries(id)) {
            if (t[tag] === value) {
              cache.delete(k);
              break;
            }
          }
        }
      } else {
        for (const [k, t] of keyTags.entries()) {
          if (t.id === id) {
            cache.delete(k);
            keyTags.delete(k);
            break;
          }
        }
      }
      id = JSON.stringify(id);
      cache.delete(id);
      cache.delete(JSON.stringify('')); // List
    },
    withCache: async (updater, id = '') => {
      const k = JSON.stringify(id);
      if (!enabled || !cache.has(k)) {
        await addPending(k, async (flush) => {
          let v;
          try {
            const r = await updater();
            v = r;
          } catch (e) {
            v = e;
          }

          cache.set(k, v);
          if (typeof id === 'object') {
            keyTags.set(k, id)
          }
          flush();
        })
      }

      const v = cache.get(k);
      if (v instanceof Error) {
        throw v;
      }
      return v;
    },
  };
}
