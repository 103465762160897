import { Tag } from "antd";
import { useMemo } from "react";
import { useResources } from "../../hooks/resources";
import Link from "../link/Link";


export default function SalesmanLabel({ code, showCode, clickable = true }) {
  const [resources] = useResources();
  const salesman = useMemo(() => resources.find(r => r.commercial_code === code), [resources, code])
  if (!code) {
    return null
  }
  if (!salesman) {
    return <Tag>{code}</Tag>
  }
  let label = salesman?.name;
  if (showCode) {
    label += ` (${code})`;
  }
  if (!clickable) {
    return label
  }
  return <Link to={"/resources/" + salesman.id}>{label}</Link>;
}