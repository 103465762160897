import { mdiClockEnd, mdiClockStart, mdiInformationBoxOutline, mdiPackageVariantClosedCheck } from "@mdi/js";

import Icon from "@mdi/react";



function milestoneParams(milestone) {
  switch (milestone) {
    case 'start':
      return {

        icon: mdiClockStart,
        color: 'green',
        textAlign: 'left'
      }
    case 'end':
      return {
        icon: mdiClockEnd,
        color: 'red',
      }
    case 'estimated-delivery':
      return {
        icon: mdiPackageVariantClosedCheck,
        color: 'gray'
      }
    case 'delivered':
      return {
        icon: mdiPackageVariantClosedCheck,
        color: 'blue'
      }
    default:
      return {
        icon: mdiInformationBoxOutline,
        color: 'gray'
      }
  }

}

export default function ResourceSlotMilestoneItem({
  schedulerData,
  event,
  bgColor,
  isStart,
  isEnd,
  mustAddCssClass,
  mustBeHeight,
  agendaMaxEventWidth,
  resourcesIdx,
}) {
  const { milestone } = event;

  const { icon, color, textAlign } = milestoneParams(milestone);
  return <div className={{}} key={event.id}
    style={{ maxHeight: mustBeHeight, textAlign: textAlign || 'right' }}>
    <Icon path={icon} size={0.8} color={color} />
  </div >
}