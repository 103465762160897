import { useCallback, useEffect, useState } from 'react';
import {
  createPickingOrder, getPickingOrder, invalidatePickingOrder, listPickingOrders, removePickingOrder, updatePickingOrder
} from '../services/api/pickingorders';
import { loaderWrap } from '../services/loader';
import { notifyError } from '../services/notification';
import { useInvalidationService } from './invalidation';



const INVALIDATION_KEY = Symbol('pickingorders');

export const pickingTypes = [
  {
    label: 'Préparation',
    value: 'picking',
    color: 'blue',
  },
  {
    label: 'Vérification',
    value: 'verifying',
    color: 'purple',
  },
  {
    label: 'Régularisation',
    value: 'fixing',
    color: 'orange',
  }
];



export const pickingPriorities = [
  {
    label: 'Basse',
    value: 1,
    color: 'green',
  },
  {
    label: 'Normal',
    value: 2,
    color: 'blue',
  },
  {
    label: 'Haute',
    value: 3,
    color: 'orange',
  },
  {
    label: 'Urgent',
    value: 4,
    color: 'red',
  },
];


function getDefaults() {
  return {
    name: "",
    capacities: {},
    orders: [],
  };
}

async function getItem(id) {
  if (id === '*' || !id) {
    return getDefaults();
  }
  try {
    return {
      ...getDefaults(),
      ...(await loaderWrap(getPickingOrder(id))),
    };
  } catch (e) {
    notifyError(e);
  }
  return {};
}

export function usePickingOrders({ orderId }) {
  const [pickingorders, setPickingOrders] = useState([]);
  const [counter, invalidate] = useInvalidationService(INVALIDATION_KEY);
  const [complete, setComplete] = useState(false);

  useEffect(() => {
    if (!orderId) {
      setPickingOrders([]);
      return;
    }
    (async () => {
      try {
        setComplete(false);
        const terms = await loaderWrap(listPickingOrders({ orderId }));
        setPickingOrders(terms);
        setComplete(true);
      } catch (e) {
        notifyError(e);
      }
    })();
  }, [counter, orderId]);

  const refresh = useCallback(() => {
    invalidatePickingOrder();
    invalidate();
  }, [invalidate]);

  return [pickingorders, refresh, complete];
}

export function usePickingOrder(id) {
  const [pickingOrder, setPickingOrder] = useState();

  const [counter, invalidate] = useInvalidationService(INVALIDATION_KEY);

  useEffect(() => {
    (async () => {
      setPickingOrder(await getItem(id));
    })();
  }, [id, counter]);

  const savePickingOrder = useCallback(async (item) => {
    if (!item) {
      throw new Error('No pickingOrder');
    }
    let out;
    if (item.id) {
      out = await loaderWrap(updatePickingOrder(item));
    } else {
      out = await loaderWrap(createPickingOrder(item));
    }
    setPickingOrder(out);
    invalidate(id);
    return out;
  }, [id, invalidate]);

  const deletePickingOrder = useCallback(async () => {
    await loaderWrap(removePickingOrder(id));
    invalidate(id);
  }, [id, invalidate]);

  return {
    pickingOrder,
    reloadPickingOrder: () => invalidate(id),
    isNewPickingOrder: !pickingOrder?.id,
    setPickingOrder,
    savePickingOrder,
    deletePickingOrder,
  };
}




