
import { useWarehouse } from "../../hooks/warehouses";
import Link from "../link/Link";


export default function WarehouseLabel({ id, code, clickable = false }) {
  if (!id && code) {
    id = 'wh-' + code; // for compatibility 
  }
  const { warehouse: wh } = useWarehouse(id);
  if (!wh?.id) {
    return <span>{id || '-'}</span>
  }
  const label = `${wh?.code} - ${wh?.name}`;
  if (!clickable) {
    return label
  }
  return <Link to={"/warehouses/" + id}>{label}</Link>;
}  