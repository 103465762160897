import {
  arrayMove,
} from '@dnd-kit/sortable';
import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { indexBy } from '../lib/utils';
import { invalidateDelivery } from '../services/api/deliveries';
import { getOrder } from '../services/api/orders';
import { invalidatePickingOrder } from '../services/api/pickingorders';
import {
  createPickingPool, getPickingPool, getPickingPoolOrders, invalidatePickingPool, listPickingPools, removePickingPool, updatePickingPool,
  updatePickingPoolOrders,
} from '../services/api/pickingpools';
import { loaderWrap } from '../services/loader';
import { notifyError } from '../services/notification';
import { useAuth } from './auth';
import { useDeliveries } from './deliveries';
import { useInvalidationService } from './invalidation';
import { useItems } from './items';
import { useSetting } from './settings';


const INVALIDATION_KEY = Symbol('pickingpools');


function getDefaults() {
  return {
    name: "",
    capacities: {},
    orders: [],
  };
}

async function getItem(id) {
  if (id === '*' || !id) {
    return getDefaults();
  }
  try {
    return {
      ...getDefaults(),
      ...(await loaderWrap(getPickingPool(id))),
    };
  } catch (e) {
    notifyError(e);
  }
  return {};
}

export function usePickingPools() {
  const [pickingpools, setPickingPools] = useState([]);
  const [counter, invalidate] = useInvalidationService(INVALIDATION_KEY);
  const [complete, setComplete] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setComplete(false);
        // const terms = await withCache(async () => loaderWrap(listPickingPools()));
        const terms = await loaderWrap(listPickingPools());
        setPickingPools(terms);
        setComplete(true);
      } catch (e) {
        notifyError(e);
      }
    })();
  }, [counter]);

  const refresh = useCallback(() => {
    invalidatePickingPool();
    invalidate();
  }, [invalidate]);

  return [pickingpools, refresh, complete];
}

export function usePickingPool(id) {
  const [pickingPool, setPickingPool] = useState();
  const [loading, setLoading] = useState(false);
  const [counter, invalidate] = useInvalidationService(INVALIDATION_KEY);

  useEffect(() => {
    if (loading) return;
    setLoading(true);
    (async () => {
      if (id) {
        setPickingPool(await getItem(id));
      }
    })().finally(() => {
      setLoading(false);
    });
  }, [id, counter]);

  const savePickingPool = useCallback(async (item) => {
    if (!item) {
      throw new Error('No pickingPool');
    }
    let out;
    if (item.id) {
      out = await loaderWrap(updatePickingPool(item));
    } else {
      out = await loaderWrap(createPickingPool(item));
    }
    setPickingPool(out);
    invalidate(id);
    return out;
  }, [id, invalidate]);

  const deletePickingPool = useCallback(async () => {
    await loaderWrap(removePickingPool(id));
    invalidate(id);
  }, [id, invalidate]);

  const warehouseId = useMemo(() => {
    if (!pickingPool || !pickingPool.is_main) {
      return null;
    }
    return Object.keys(pickingPool.capacities)[0];
  }, [pickingPool]);

  return {
    pickingPool,
    loading,
    complete: !loading && !!pickingPool,
    warehouseId,
    reloadPickingPool: () => invalidate(id),
    isNewPickingPool: !pickingPool?.id,
    setPickingPool,
    savePickingPool,
    deletePickingPool,
  };
}

export function usePickingPoolAllOrders(warehouse) {
  const [pools] = usePickingPools();
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    if (!pools || !warehouse) {
      return;
    }
    (async () => {
      const poolsorders = await Promise.all(pools
        .filter(p => !p.is_main)
        .map(async ({ id }) => {
          return loaderWrap(getPickingPoolOrders(id))
        }))

      const sorted = []
      const addedOrder = {}
      let i = 0
      // Add order sequentialy of each pool
      while (i < 100) {

        let added = false
        for (const orderIdx in poolsorders) {
          const orders = poolsorders[orderIdx]
          const pool = pools[orderIdx]
          if (orders.length > i && !addedOrder[orders[i].id]) {
            sorted.push({ ...orders[i], pool_id: pool.id, pool_name: pool.name, position: i })
            addedOrder[orders[i].id] = true
            added = true;
          }
        }
        if (!added) {
          break
        }
        i++
      }
      setOrders(sorted)

    })()
  }, [pools, warehouse]);

  return orders;
}


async function loadPickingPoolOrders(pickingPool) {
  if (!pickingPool?.orders) return [];
  const ppo = indexBy(pickingPool.orders || [], 'order_id');

  return getPickingPoolOrders(pickingPool.id)
    .then(orders => orders.map(o => ({
      ...o,
      preparation_date_min: ppo[o.id]?.date_min ? new Date(ppo[o.id].date_min) : null,
      preparation_date_max: ppo[o.id]?.date_max ? new Date(ppo[o.id].date_max) : null,
      estimated_date_min: ppo[o.id]?.estimated_date_min || null,
      estimated_date_max: ppo[o.id]?.estimated_date_max || null,
      locked: ppo[o.id]?.locked || false,
      shipment_id: ppo[o.id]?.shipment_id || null,
      from_pool_id: ppo[o.id]?.from_pool_id || null,
      in_main_pool: pickingPool.is_main || false,
    })))
}

function loadPickingPoolMetrics(pickingPool, orders, items = []) {
  if (!orders || !pickingPool || !pickingPool.id) return;
  const metrics = {};
  const consumedStocks = {};

  const poolConsumedCapacities = {};

  if (orders.length === 0) return {};

  const itemsByCode = indexBy(items || [], 'code');

  orders.forEach((order) => {
    let consumedByWarehouse = {};
    let consumedCapacities = {};
    let total = 0;
    let completed = 0;
    // let outOfCapacity = false;
    (order.items || []).forEach(({ item_code, item_type, quantity, warehouse }) => {
      if (item_type !== 'MAR' && item_type !== 'NOM') return;
      const warehouseId = 'wh-' + warehouse;
      const { stocks } = itemsByCode[item_code] || { stocks: {} };
      // console.log('stocks', code)
      const stockKey = `${item_code}@${warehouse}`;
      const consumed = consumedStocks[stockKey] || 0;
      const stock = stocks[warehouse] || 0;
      const used = Math.min(quantity, Math.max(stock - consumed, 0));
      total += quantity;
      completed += Math.min(quantity, stock);
      consumedStocks[stockKey] = consumed + used;
      consumedCapacities[warehouseId] = (consumedCapacities[warehouseId] || 0) + 1;
      poolConsumedCapacities[warehouseId] = (poolConsumedCapacities[warehouseId] || 0) + 1;
      consumedByWarehouse[warehouseId] = (consumedByWarehouse[warehouseId] || 0) + used;
    });

    // const estimatedDates = Object.entries(poolConsumedCapacities).reduce((dates, [wh, qty]) => {
    //   const whCap = pickingPool.capacities[wh] || 0
    //   if (!whCap) return dates;

    //   let nextDate = new Date()
    //   const currentWeekCapacity = adjustCapacity(whCap, 'week');
    //   // If we are out of capacity for the current week, we go to the next week
    //   if (qty > currentWeekCapacity) {
    //     nextDate = add(endOfISOWeek(nextDate), { hours: 12 });
    //     qty -= currentWeekCapacity;
    //   }

    //   nextDate = addBusinessDays(nextDate, Math.floor(qty / whCap));
    //   dates[wh] = nextDate;
    //   return dates;
    // }, {});

    // let estimatedDateMax = Object.values(estimatedDates).reduce((acc, d) => d ? (acc ? (d > acc ? d : acc) : d) : acc, null);
    // if (!estimatedDateMax) {
    //   estimatedDateMax = new Date();
    // }
    // if (order.preparation_date_min && order.preparation_date_min > estimatedDateMax) {
    //   estimatedDateMax = order.preparation_date_min;
    // } else if (order.preparation_date_max && order.preparation_date_max < estimatedDateMax) {
    //   estimatedDateMax = order.preparation_date_max;
    // }

    metrics[order.id] = {
      total,
      completed,
      percent: total ? Math.round(completed / total * 100) : 0,
      consumedByWarehouse,
      consumedCapacities,
      // estimatedDates,
      // estimatedDateMax,
      // outOfCapacity
    };
  });

  return metrics;

}



export function usePickingPoolOrders(id, autoSave = false) {
  const { pickingPool, warehouseId, reloadPickingPool, complete } = usePickingPool(id);
  const [items] = useItems()
  const [orders, setOrders] = useState([]);
  const [saving, setSaving] = useState(false);
  const [changed, setChanged] = useState(false);
  const [silent, setSilent] = useState(false);

  // const [autoSave, setAutoSave] = useState(false);

  const [forceNextSave, setForceNextSave] = useState(false);

  const [orderCompletions, setOrderCompletions] = useState({});

  useEffect(() => {

    loaderWrap(loadPickingPoolOrders(pickingPool))
      .then(setOrders)
      .finally(() => {
        setSilent(false);
      });
  }, [pickingPool]);



  useEffect(() => {
    const metrics = loadPickingPoolMetrics(pickingPool, orders, items)
    if (!metrics) return;

    setOrderCompletions(metrics);

  }, [items, orders, pickingPool]);

  const moveOrder = ({ active, over }) => {
    if (active.id !== over?.id) {
      setOrders((previous) => {
        const activeIndex = previous.findIndex((i) => i.id === active.id);
        const overIndex = previous.findIndex((i) => i.id === over?.id);
        return arrayMove(previous, activeIndex, overIndex);
      });
      setChanged(true);
    }
  };



  const saveOrders = useCallback(async (force) => {
    // console.log('saveOrders', { changed, saving })
    if (!pickingPool) return false;
    if (!changed) return false;
    if (saving) return false;
    setSaving(true);

    await loaderWrap(updatePickingPoolOrders(pickingPool.id,
      orders.map(o => ({
        order_id: o.id,
        date_min: o.preparation_date_min,
        date_max: o.preparation_date_max,
        from_pool_id: o.from_pool_id,
      })),
      forceNextSave || force
    )).finally(() => {
      setSaving(false);
      setChanged(false);
      setForceNextSave(false);

      reloadPickingPool();

    });
    return true;
  }, [pickingPool, forceNextSave, changed, saving, orders]);

  useEffect(() => {
    if (!autoSave) return;
    if (changed) {
      setSilent(true);
      saveOrders();

    }
  }, [autoSave, changed]);

  return {
    pickingPool,
    warehouseId,
    orders,
    orderCompletions,
    saving,
    complete: complete || silent,
    reloadPickingPool,
    // consumedCapacities,
    addOrder: async (orderId, fromPoolID = undefined) => {
      const { item: order } = await loaderWrap(getOrder(orderId))
      setOrders(orders => [
        ...orders,
        { ...order, from_pool_id: fromPoolID }
      ]);
      setChanged(true);
      // save();
    },
    updateOrder: (orderId, { preparation_date_min, preparation_date_max }) => {
      setOrders(orders => {
        // First locate position
        const newOrders = [...orders.map(o => o.id === orderId ? { ...o, ...{ preparation_date_min, preparation_date_max } } : o)]
        const activeIndex = orders.findIndex(o => o.id === orderId)
        if (preparation_date_min) {
          const overIndex = orders.findIndex(o => o.id !== orderId && preparation_date_min < orderCompletions[o.id].estimatedDateMax)
          return arrayMove(newOrders, activeIndex, overIndex);
        } else if (preparation_date_max) {
          const dateMax = new dayjs(preparation_date_max).add(-1, 'day').toISOString().slice(0, 10)
          const overIndex = orders.findIndex(o => {
            // console.log(o.id, orderId, dateMax, new Date(orderCompletions[o.id].estimatedDateMax).toISOString().slice(0, 10))
            return o.id !== orderId && dateMax < new Date(orderCompletions[o.id].estimatedDateMax).toISOString().slice(0, 10)
          })
          // console.log(overIndex)
          return arrayMove(newOrders, activeIndex, overIndex);
        }
        return newOrders
      });
      setChanged(true);
      // save();
    },
    removeOrder: (orderId, force) => {
      setOrders(orders => [
        ...orders.filter(o => o.id !== orderId)
      ]);
      if (force) {
        setForceNextSave(force);
      }
      console.log('removeOrder', orderId)
      setChanged(true);
      // save();
    },
    moveOrder,
    saveOrders,
    // enableAutoSave: () => {
    //   setAutoSave(true);
    // },
    // disableAutoSave: () => {
    //   setAutoSave(false);
    // }
  };
}


export function useSelectedPickingPoolID() {
  const [selectedPickingPoolID, setSelectedPickingPoolID] = useSetting('pickingPool.selected', null);
  const [loaded, setLoaded] = useState(false);
  const { defaultPoolId } = useAuth()

  const selectPickingPool = useCallback((id) => {
    setSelectedPickingPoolID(id)
  }, [setSelectedPickingPoolID]);


  // Load
  useEffect(() => {
    (async () => {
      if (defaultPoolId) {
        selectPickingPool(defaultPoolId);
      } else if (!loaded) {
        if (selectedPickingPoolID) {
          selectPickingPool(selectedPickingPoolID);
        }
        setLoaded(true);
      }
    })();
  }, [loaded, selectPickingPool, defaultPoolId, selectedPickingPoolID]);

  return [
    selectedPickingPoolID,
    selectPickingPool,
  ]
}


export function useSelectedPickingPool() {
  const [selectedPickingPoolID] = useSelectedPickingPoolID();
  const [selectedPickingPool, setSelectedPickingPool] = useState(null);

  useEffect(() => {
    (async () => {
      if (selectedPickingPoolID) {
        const a = await getPickingPool(selectedPickingPoolID)
        setSelectedPickingPool(a);
      } else {
        setSelectedPickingPool(null);
      }
    })()
  }, [selectedPickingPoolID])

  const warehouseId = useMemo(() => {
    if (!selectedPickingPool || !selectedPickingPool.is_main) {
      return null;
    }
    return Object.keys(selectedPickingPool.capacities)[0];
  }, [selectedPickingPool]);

  return [
    {
      ...selectedPickingPool,
      warehouse_id: warehouseId,
    },
  ]
}

// Return the list of picking pools orders with date_min and date_max
export function usePickingPoolOrdersShippingStates() {
  const [pickingPools, refresh, complete] = usePickingPools();
  const [deliveries, refreshDeliveries, deliveriesComplete] = useDeliveries({ onlyActive: true })
  const [orderStates, setOrderStates] = useState([]);
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    if (complete && deliveriesComplete && !pickingPools) {
      setLoaded(true);
    }
    if (!pickingPools && !deliveriesComplete) return;
    const states = {};

    (async () => {
      await Promise.all(pickingPools.map(async (pp) => {
        const orders = await loaderWrap(loadPickingPoolOrders(pp))
        const metrics = loadPickingPoolMetrics(pp, orders, [])
        if (!metrics) return;
        console.log(orders)
        const ordersIdx = indexBy(orders, 'id')

        for (let orderId in metrics) {
          if (!states[orderId]) {
            states[orderId] = {
              pool: pp,
              metrics: metrics[orderId],
              order: ordersIdx[orderId],
            }
          } else if (pp.is_main) {
            // TODO merge different main pickingpool if needed
            states[orderId] = {
              metrics: metrics[orderId],
              order: ordersIdx[orderId],
            }
          }
        }
      }))

      const out = []

      for (let orderId in states) {
        const { order, metrics, pool } = states[orderId]
        // console.log(order)
        out.push({
          id: orderId,
          ...metrics,
          projectId: order.project_id,
          inMainPool: pool.is_main,
          orderNumber: order.order_number,
          shipmentId: order.shipment_id,
          fromPoolId: pool.id,
          locked: order.locked,
          shippingDate: order.shipping_date,
        })
      }
      setLoaded(true);
      setOrderStates(out)

    })()
  }, [pickingPools, deliveries, complete, deliveriesComplete]);

  return {
    states: orderStates,
    complete: loaded,
    reload: () => {
      setLoaded(false);
      invalidatePickingOrder();
      refresh()
      invalidateDelivery();
      refreshDeliveries();
    }
  }

}