import { CheckCircleOutlined, Loading3QuartersOutlined, StopOutlined } from "@ant-design/icons"
import { ConfigProvider, InputNumber, Skeleton, Table } from "antd"
import { useMemo } from "react"
import { useOrder } from "../../hooks/orders"




export default function PickingProducts({ orderId, items }) {

    const { order, loading } = useOrder(orderId)

    const list = useMemo(() => {
        if (!items || !order) return []
        return items?.map(item => {
            const line = order.items.find(i => i.line_number === item.line_number)
            return {
                ...line,
                ...item
            }
        }).filter(item => item.expected_quantity)
    }, [items, order])

    if (loading) return <Skeleton active />

    return <Table
        dataSource={list}
        rowKey="line_number"
        size='small'
        pagination={
            {
                position: ['bottomCenter'],
            }
        }

        columns={[
            {
                title: 'Ligne',
                dataIndex: 'line_number',
                key: 'line_number',
                filterSearch: true,
                onFilter: (value, record) => record.line_number.includes(value),
            },
            {
                title: 'Référence',
                dataIndex: 'item_code',
                key: 'item_code',
            },
            {
                title: 'Nom',
                dataIndex: 'label',
                key: 'label',
            },
            {
                title: 'Quantité commandée',
                dataIndex: 'quantity',
                key: 'quantity',
                width: 100,
            },
            {
                title: 'Quantité attendue',
                dataIndex: 'expected_quantity',
                key: 'expected_quantity',
                width: 100,
            },
            {
                title: 'Préparé',
                dataIndex: 'picked_quantity',
                key: 'picked_quantity',
                render: (picked_quantity, record) => {
                    let color = 'green'
                    let icon = <CheckCircleOutlined style={{ color }} />
                    let changed = false
                    if (picked_quantity < record.remain_quantity) {
                        color = 'orange'
                        icon = <Loading3QuartersOutlined style={{ color }} />
                        changed = true
                    }
                    if (+picked_quantity === 0) {
                        color = 'red'
                        icon = <StopOutlined style={{ color }} />
                    }
                    return <ConfigProvider
                        theme={{
                            components: {
                                InputNumber: {
                                    colorText: color,
                                }
                            }
                        }}
                    ><InputNumber
                            suffix={icon}
                            style={{ color, fontWeight: changed ? 'bold' : 'normal' }}
                            value={picked_quantity}
                            max={record.remain_quantity}
                            min={0}
                            step={1}
                            disabled

                        /></ConfigProvider>
                }
            }
        ]} />
}

