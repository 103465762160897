import { cache } from '../cache';
import { withApi } from './api';

const api = withApi('chronos', '/orders');
const { invalidateCache, withCache } = cache();

function mapFromOrderModel(input) {
  return { ...input };;
}

export function mapToOrderModel(input) {
  return { ...input };;
}


export async function listOrders(dateFrom, dateTo) {
  if (!dateFrom || !dateTo) {
    throw new Error('dateFrom and dateTo are required');
  }
  const items = await withCache(async () => {
    const { items } = await api.get('', {}, {
      dateFrom: dateFrom.toISOString().split('.')[0] + 'Z',
      dateTo: dateTo.toISOString().split('.')[0] + 'Z',
    });
    return items;
  }, [dateFrom, dateTo].join())
  // XXX attention le cache ne s'invalide que sur des clés,
  // ici on a une clé qui dépend de dateFrom et dateTo ...
  return items ? items.map(mapToOrderModel) : [];
}

export async function getOrder(id, { withLogs = false } = {}) {
  const { item, changes } = await withCache(async () => {
    const { item, changes } = await api.get(id, {}, { withLogs });
    if (!item) {
      throw new Error('Order not found');
    }
    return { item, changes };
  }, id + (withLogs ? '+logs' : ''))

  return {
    item: mapToOrderModel(item),
    changes: changes,
  };
}

// export async function createOrder(order) {
//   const { item } = await api.post('', { item: mapFromOrderModel(order) });
//   if (item) {
//     invalidateCache(item.id)
//     return mapToOrderModel(item)
//   }
//   return undefined;
// }

export async function updateOrder(order) {
  const res = await api.put('', { item: mapFromOrderModel(order) });
  invalidateCache()
  return res ? order : undefined;
}

// export async function removeOrder(id) {
//   const res = await api.delete(id);
//   invalidateCache(id)
//   return !!res;
// }


export async function listOrderComments(id) {
  const { items } = await api.get(`${id}/comments`);
  return items;
}

export async function addOrderComment(id, comment) {
  const { success } = await api.post(`${id}/comments`, { content: comment });
  return success;
}

export function invalidateOrderCache(id) {
  invalidateCache(id)
}