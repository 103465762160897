import { cache } from '../cache';
import { withApi } from './api';
import { mapToOrderModel } from './orders';

const api = withApi('chronos', '/pickingorders');
const { invalidateCache, withCache } = cache();

function mapFromPickingOrderModel(input) {
  return { ...input };;
}

function mapToPickingOrderModel(input) {
  return { ...input };;
}


export async function listPickingOrders({ orderId }) {
  const params = {
    order_id: orderId,
    list: true
  }
  const items = await withCache(async () => {
    const { items } = await api.get('', {}, params);
    return items;
  }, params)
  return items ? items.map(mapToPickingOrderModel) : [];
}

export async function getPickingOrder(id) {
  const item = await withCache(async () => {
    const { item } = await api.get(id);
    if (!item) {
      throw new Error('PickingOrder not found');
    }
    return item;
  }, id)

  return mapToPickingOrderModel(item);;
}

export async function createPickingOrder(pickingpool) {
  const { item } = await api.post('', { item: mapFromPickingOrderModel(pickingpool) });
  if (item) {
    invalidateCache(item.id)
    invalidateCache({ list: true })
    return mapToPickingOrderModel(item)
  }
  return undefined;
}

export async function updatePickingOrder(pickingpool) {
  const res = await api.put('', { item: mapFromPickingOrderModel(pickingpool) });
  invalidateCache(pickingpool.id)
  invalidateCache({ list: true })
  return res ? pickingpool : undefined;
}

export async function updatePickingOrderOrders(id, orders) {
  const res = await api.put(id + "/orders", { orders });
  invalidateCache(id)
  invalidateCache({ list: true })
  return
}

export async function getPickingOrderOrders(id) {
  const items = await withCache(async () => {
    const { items } = await api.get(id + "/orders");
    return items;
  }, id + '/orders')
  return items ? items.map(mapToOrderModel) : [];
}


export async function removePickingOrder(id) {
  const res = await api.delete(id);
  invalidateCache(id)
  invalidateCache({ list: true })
  return !!res;
}



export function invalidatePickingOrder(id) {
  invalidateCache(id);
}