import { CloseOutlined, PlusOutlined } from "@ant-design/icons"
import { Button, DatePicker, Divider, Flex, Form, Input, Progress, Rate, Segmented, Select, Skeleton, Space, Switch, Tooltip, Typography } from "antd"
import dayjs from "dayjs"
import { useEffect, useMemo, useState } from "react"
import { autoPlan, durationInDaysFromHours, roundDays, useSlots } from "../../hooks/slots"
import { taskUnits } from "../../hooks/tasks"
import { formatCurrency } from "../../lib/format"
import { getPrestation } from "../../services/api/prestations"
import { listResourcePrestationsForResource } from "../../services/api/resources-prestations"
import { listSlots } from "../../services/api/slots"
import { ResourceForPrestationInput } from "../resources/ResourceForPrestationInput"
import SlotStatusLabel from "../slots/SlotStatusLabel"
import TaskMessagesView from "../tasks/TaskMessagesView"

import { useOrder } from "../../hooks/orders"
import { indexBy } from "../../lib/utils"
import { getTask } from "../../services/api/tasks"
import Link from "../link/Link"
import ProjectLabel from "../projects/ProjectLabel"
import SalesmanLabel from "../resources/SalesmanLabel"
import { TaskInput } from "../tasks/TaskInput"
import TaskUnitLabel from "../tasks/TaskUnitLabel"
import Style from "./PlanningSlotForm.module.scss"

export const COST_BY_DAY = 945


function Prop({ label, value, children }) {
  return <Flex gap="small" >
    {label && <div>{label} : </div>}
    {children || <b>{value}</b>}
  </Flex>
}


export default function PlanningSlotForm({ form,
  slot, task, resource,

  tasks,
  hideTaskInfo
}) {


  const [prestations, setPrestations] = useState([])
  const [forceDisplayDateRange, setForceDisplayDateRange] = useState(false)
  const [ownSlots, loadOwnSlots] = useSlots();
  const [busyResources, setBusyResources] = useState([])
  const [prestationInfo, setPrestationInfo] = useState({})
  const [suggestedDuration, setSuggestedDuration] = useState(0)

  const [selectedTask, setSelectedTask] = useState(task)
  const [selectedTaskLoading, setSelectedTaskLoading] = useState(false)



  useEffect(() => {
    (async () => {
      if (!slot) return
      const slots = await listSlots({ dates: [slot.start, slot.end] })

      const start = new dayjs(slot.start)
      const end = new dayjs(slot.end)
      const res = Array.from(new Set(slots?.filter(s => {
        if (s.id === slot.id) {
          return false; // it's the current slot, ignore it
        }
        if (start.isSameOrAfter(s.end)) {
          return false; // it's before the slot, ignore it
        }
        if (end.isSameOrBefore(s.start)) {
          return false; // it's after the slot, ignore it
        }
        return true;
      })
        .map(s => s.resource_id)))
      setBusyResources(res)
    })()


  }, [slot])


  useEffect(() => {
    if (!resource?.id) {
      return
    }
    (async () => {
      const prestas = await listResourcePrestationsForResource(resource.id)
      setPrestations(prestas)
    })()
  }, [resource?.id])



  // const isUnit = task?.unit === 'u'


  const type = Form.useWatch("type", { form })
  const auto = Form.useWatch("auto", { form })
  const formResourceId = Form.useWatch("resource_id", { form })
  const taskId = Form.useWatch("task_id", { form })
  const toPlanDuration = Form.useWatch("toplan_duration", { form })
  const toPlanUnit = Form.useWatch("toplan_unit", { form })
  const suggestedSlots = Form.useWatch("suggested_slots", { form, preserve: true })
  const allTasks = Form.useWatch("all_tasks", { form })
  // const selectedTask = useMemo(() => {
  //   if (task) {
  //     return task
  //   }
  //   return tasks.find(t => t.id === taskId)
  // }, [task, taskId, tasks])


  useEffect(() => {

    if (task && task?.id) {
      setSelectedTask(task)
      return
    }

    if (!taskId || selectedTaskLoading) {
      return
    }

    if (selectedTask?.id === taskId) {
      return
    }
    (async () => {
      setSelectedTaskLoading(true)
      const { item } = await getTask(taskId)
      // console.log(taskId, t)
      setSelectedTask(item)
    })().finally(() => {
      setSelectedTaskLoading(false)
    })

  }, [task, taskId, selectedTaskLoading, selectedTask])

  const { order } = useOrder(selectedTask?.order_id);

  const resourceId = resource?.id || formResourceId

  const isInterval = !slot.start.isSame(slot.end, 'day')
  const isTask = type === "task"
  const isAuto = auto && type === "task"

  useEffect(() => {
    if (!selectedTask) return
    loadOwnSlots({ task_id: selectedTask.id })
  }, [selectedTask, loadOwnSlots])

  useEffect(() => {
    if (!ownSlots || resourceId) {
      return
    }
    const sorted = ownSlots.sort((a, b) => a.start < b.start)
    if (sorted.length === 0) {
      return
    }
    const last = sorted[0]
    form.setFieldsValue({ resource_id: last.resource_id })
  }, [busyResources, resourceId, ownSlots, form])


  // Init form

  useEffect(() => {
    if (!slot || !selectedTask || !selectedTask.metrics) return

    let duration = Math.max(0, selectedTask.metrics.duration - selectedTask.metrics.planned_duration);

    setForceDisplayDateRange(durationInDaysFromHours(duration) > 1)
    let unit = selectedTask.unit || 'h'
    if (unit === 'u') {
      unit = 'd'
    }

    if (unit === 'd') {
      duration = durationInDaysFromHours(duration)
    }



    if (unit === 'd' && duration <= 1 && selectedTask.metrics?.cost > (COST_BY_DAY * 1.4)) {
      const d = roundDays(selectedTask.metrics?.cost / COST_BY_DAY)
      setSuggestedDuration(roundDays(d))
    }

    form.setFieldsValue({
      estimated_duration: slot.duration || duration,
      auto: true,
      toplan_duration: duration,
      toplan_unit: unit,
      unit: selectedTask.unit,
    })

  }, [form, slot, selectedTask])




  useEffect(() => {
    if (!selectedTask || !resourceId) {
      return
    }
    (async () => {
      const basePresta = await getPrestation(selectedTask.prestation_id)

      const prestas = await listResourcePrestationsForResource(resourceId)
      const presta = prestas.find(p => p.prestation_id === selectedTask.prestation_id)


      setPrestationInfo({
        performance: presta?.performance || 100,
        price: presta?.price || basePresta?.price || null,
        purchase_price: presta?.purchase_price || basePresta?.purchase_price || null,
      })

    })()
  }, [resourceId, selectedTask])

  const toPlanDurationPerformed = roundDays(toPlanDuration * 1 / ((prestationInfo?.performance || 100) / 100));

  // Plan until the end of duration only on the working days
  useEffect(() => {
    if (!resourceId || !slot?.start || !isAuto || !toPlanDurationPerformed) return

    // const toPlanDurationPerformed = toPlanDuration * (prestationInfo?.performance || 100 / 100)

    (async () => {
      const slots = await autoPlan({
        from: slot.start,
        type,
        resourceId: resourceId,
        unit: toPlanUnit,
        duration: toPlanDurationPerformed,
      })
      form.setFieldsValue({
        suggested_slots: slots,
      })
    })()
  }, [form, toPlanDurationPerformed, toPlanUnit, slot, type, resourceId, isAuto, prestationInfo])


  const filteredTasks = useMemo(() => {
    if (allTasks) {
      return tasks
    }
    const idx = indexBy(prestations, "prestation_id")
    // console.log(idx)
    const out = tasks.filter(t => idx[t.prestation_id])
    if (selectedTask && !out.find(t => t.id === selectedTask.id)) {
      out.push(selectedTask)
    }
    return out
  }, [tasks, taskId, prestations, allTasks, selectedTask])



  const isNew = !slot.id
  const displayAsDateRange = forceDisplayDateRange || isInterval || !isAuto
  const displayHours = type === 'time' || type === 'unavailablity'

  const unavailablity = type === "unavailablity" || slot?.status === "unavailable"

  const { cost, duration: duration_hours, planned_cost, planned_duration,
    slots_count, slots_planned_count, slots_confirmed_count, slots_complete_count } = selectedTask?.metrics || {};


  return <Flex justify="stretch">
    {!hideTaskInfo && !unavailablity && <>
      {selectedTaskLoading && <Skeleton active style={{ width: 350 }} />}
      {!selectedTaskLoading && selectedTask ?
        <>
          <div style={{ minWidth: 350, maxWidth: 350, borderRight: "1px solid #eee", paddingRight: 15 }}>
            {!task && <Prop label="" value={selectedTask.name} />}
            <Flex justify="space-between" align="center">
              <Rate disabled value={Math.ceil(selectedTask?.expected_level * 3 / 5)} style={{ fontSize: '0.8em' }} count={3} />
              <SlotStatusLabel status={slot?.status} />
            </Flex>

            {selectedTask?.project_id && <Prop label="Affaire" value={<ProjectLabel id={selectedTask?.project_id} />} />}
            {selectedTask?.order_id && <Prop label="Commande">
              <Flex gap="small" align="center" style={{ flex: 1 }}>
                <div>
                  <Link to={`/orders/${selectedTask.order_id}`}>{selectedTask.order_number}{selectedTask.order_item_number ? <> / {selectedTask.order_item_number}</> : null}</Link>
                </div>
                {order && <div style={{ width: 50 }}>
                  <Tooltip title={<>Marchandises traitées : <b>{order.items_count - order.remaining_items_count} / {order.items_count}</b></>} >
                    <Progress
                      size="small"
                      percent={Math.round(100 * (order.items_count - order.remaining_items_count) / order.items_count)} />
                  </Tooltip>
                </div>}
              </Flex>
            </Prop>}
            {selectedTask?.salesman_code && <Prop label="Commercial" value={<SalesmanLabel code={selectedTask.salesman_code} clickable={false} showCode />} />}
            {cost > 0 ? <Prop label="Vendu" value={formatCurrency(cost)} /> : null}

            {selectedTask?.description && <div className="form-text" style={{ maxHeight: 200, overflow: 'auto' }}>
              {selectedTask.description.split('\n').map((line, i) => <span key={i}>{line}<br /></span>)}
            </div>}
            <Divider />
            <TaskMessagesView taskId={selectedTask?.id} />
          </div>
        </> : null}
    </>}

    <Form
      form={form}
      // layout="vertical"
      labelWrap
      labelCol={{ span: 5 }}
      wrapperCol={{ span: 32 }}
      style={{ flex: 1 }}
    >


      <Form.Item label="Mode" >
        <Flex gap="middle">
          <div>
            <Form.Item name="type" noStyle >
              <Segmented options={[
                { value: 'task', label: 'planification' },
                { value: 'time', label: 'à heure précise' },
                ...(task ? [] : [{ value: 'unavailablity', label: 'indisponibilité' }]),
              ]} />
            </Form.Item>
          </div>
          {isNew && !isInterval && isTask &&
            <div style={{ marginBottom: -24 }}><Form.Item name="auto">
              <Switch checkedChildren="auto" unCheckedChildren="man." style={{ marginBottom: 0 }} />
            </Form.Item>
            </div>}
        </Flex>
      </Form.Item>
      {isAuto ?
        <Form.Item label="Durée à planifier" >
          <Flex vertical>
            <Space.Compact>
              <Form.Item noStyle name="toplan_duration" rules={[
                { required: true, message: "Veuillez spécifier une durée" },
                {
                  validator: (_, value) =>
                    value >= 0.5 ? Promise.resolve() : Promise.reject(new Error('Veuillez spécifier une durée supérieure à 0')),
                }
              ]}>
                <Input type="number" style={{ maxWidth: 70 }} min={0} variant="borderless" />
              </Form.Item>
              <Form.Item noStyle name="toplan_unit">
                <Select style={{ width: 100 }} variant="borderless" options={taskUnits} />
              </Form.Item>
            </Space.Compact>
            {suggestedDuration > toPlanDuration && <Tooltip title={`Dans certains cas les lignes sont saisie en considérant un forfait, Chronos essaye donc de déterminer de façon arbitraire un nombre de jour en divisant le cout de la ligne par ${COST_BY_DAY}€`}><Typography.Text type="success" >
              Durée suggérée: <Button type="link" onClick={() => {
                form.setFieldsValue({ toplan_duration: suggestedDuration })
              }}><b>{suggestedDuration} <TaskUnitLabel value={toPlanUnit} lowercase /></b></Button>
            </Typography.Text></Tooltip>}
            {Math.abs(toPlanDurationPerformed - toPlanDuration) > 0.01 && <Typography.Text type="secondary">
              <Tooltip title="La durée ajustée est calculée en fonction de la performance de la ressource">
                Durée ajustée à <b>{toPlanDurationPerformed} <TaskUnitLabel value={toPlanUnit} lowercase /></b>
              </Tooltip></Typography.Text>}
          </Flex>
        </Form.Item>
        : null}
      {!resource && <Form.Item label={task ? "Affecté à" : "Ressource"} name="resource_id" rules={[{ required: true, message: "Veuillez spécifier une ressource affectée" }]}>
        <ResourceForPrestationInput
          prestationId={task?.prestation_id}
          agencyId={task?.agency_id}
          expectedLevel={task?.expected_level}
          showPerformance
          busyResources={busyResources} />
      </Form.Item>}
      {!unavailablity && !task && <Form.Item label={"Tâche"} >
        <Flex vertical justify="end">
          <Form.Item noStyle name="task_id" rules={[{ required: true, message: "Veuillez spécifier une tâche affectée" }]}>
            <TaskInput tasks={filteredTasks} />
          </Form.Item>
          <div>
            <Form.Item name="all_tasks" label="Toutes les tâches">
              <Switch size="small" />
            </Form.Item>
          </div>
        </Flex>
      </Form.Item>}
      <Form.Item label="Dates" >
        {!isAuto
          ? (displayAsDateRange ?

            <Form.Item noStyle label="Date" name="dates" rules={[{ required: true, message: "Veuillez spécifier un intervale de date" }]}>
              <DatePicker.RangePicker format={"DD/MM/YYYY" + (displayHours ? " HH:mm" : "")}
                showTime={displayHours ? { format: 'HH:mm', minuteStep: 15 } : false}
                variant="borderless"
                disabled={isAuto} />

            </Form.Item> :
            <Form.Item noStyle label="Date" name={["dates", 0]} rules={[{ required: true, message: "Veuillez spécifier une date" }]}>
              <DatePicker format={"DD/MM/YYYY"} variant="borderless" />
            </Form.Item>
          )
          : <div>
            {(suggestedSlots?.length === 0 || toPlanDuration <= 0) && <Typography.Text type="warning">Il n'y a pas de temps à planifier</Typography.Text>}
            {suggestedSlots?.map(({ start, end, duration, disabled }, i) => {
              return <Flex key={i} align="center" className={disabled ? Style.strike : null}>
                <DatePicker.RangePicker
                  format={"DD/MM/YYYY" + (displayHours ? " HH:mm" : "")}
                  value={[dayjs(start), dayjs(end)]}
                  showTime={displayHours ? { format: 'HH:mm', minuteStep: 15 } : false}
                  variant="borderless"
                  disabled={isAuto} />
                <div>({duration}h)</div>
                <Button size="small" type="text" icon={disabled ? <PlusOutlined /> : <CloseOutlined />} onClick={() => {
                  const slots = suggestedSlots.map((_, j) => {
                    return {
                      ..._,
                      disabled: (i === j) ? !_.disabled : _.disabled
                    }
                  })
                  form.setFieldsValue({ suggested_slots: slots })
                }} />
              </Flex>
            })}
          </div>
        }
      </Form.Item>

      {isTask && !isAuto && <Form.Item label="Durée estimée" name="estimated_duration" rules={[
        { required: true, message: "Veuillez spécifier une durée" },
        {
          validator: (_, value) =>
            value >= 0.5 ? Promise.resolve() : Promise.reject(new Error('Veuillez spécifier une durée supérieure à 0')),
        }]}>
        <Input type="number" min={1} suffix="Heures" style={{ maxWidth: 130 }} />
      </Form.Item>}





    </Form>
  </Flex>
}