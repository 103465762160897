
import { useCallback, useEffect, useState } from "react";
import { getSetting, setSetting } from "../services/api/settings";



export function useSetting(key, initialValue) {
  const [value, setValue] = useState(initialValue);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    getSetting(key).then(({ value, exists }) => {
      try {
        if (!exists) {
          return;
        }
        setValue(value);
      } catch (e) {
        console.warn(e);
      }
    }).finally(() => {
      setLoaded(true);
    });
  }, [key]);


  const setState = useCallback(async (v) => {
    if (v === value) {
      return;
    }
    await setSetting({ key, value: v });
    setValue(v);
  }, [key, value]);

  return [value, setState, loaded];
}